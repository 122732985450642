import { Fragment, useState, useEffect } from 'react';
import './Language.scss';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from "react-i18next";

const languages = [
  {
    id: 'vi',
    name: `Tiếng Việt`,
    icon: '/images/icons/flag-vi.svg'
  },
  {
    id: 'en',
    name: 'English',
    icon: '/images/icons/flag-en.svg'
  },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

const Language = (props: any) => {
  const { mobile } = props;
  const [selected, setSelected] = useState(null as any);
  const { i18n = {} as any } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem('lang');

    if (lang) {
      lang === 'vi' ? setSelected(languages[0]) : setSelected(languages[1]);
      localStorage.setItem("lang", lang);
      i18n.changeLanguage(lang);
    } else {
      setSelected(languages[0]);
      localStorage.setItem("lang", "vi");
      i18n.changeLanguage("vi");
    }
  }, [selected, i18n]);

  const handleChangeLang = (language: any) => {
    if (language !== selected) {
      localStorage.setItem('lang', language.id);
      setSelected(language);
      window.location.reload();
    }
  }

  if (selected) {
    return (
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <div className="relative dropdown">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <img src={selected.icon} alt="" className="h-5 w-5" />
                  {
                    !mobile &&
                    <span className="ml-3 block truncate selected-language">{selected.name}</span>
                  }
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {languages.map((item: any, index: number) => (
                    <Listbox.Option
                      key={`${item.id}_${index}`}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                      onClick={() => handleChangeLang(item)}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <img src={item.icon} alt="" className="h-5 w-5" />

                            {
                              !mobile &&
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                              >
                                {item.name}
                              </span>
                            }

                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    )
  } else {
    return (
      <></>
    )
  }
}

export default Language;