import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './Coupon.scss';

const CouponUI = (props: any) => {
  const { t, CouponForm } = props;
  const breadcrumbItems = [
    {
      title: t('coupon-management'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="coupon">
        <div className="coupon-form__header">
          <div className="header-title">{t('coupon-management')}</div>
        </div>

        <CouponForm
          t={t}
        />
      </div>
    </Desktop>
  )
}

export default CouponUI;