import { useState, Fragment } from 'react';
import './user.scss';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

const User = (props: any) => {
  const { userInfo } = props;
  const [selected, setSelected] = useState(null as any);
  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage.removeItem('usr');
    window.location.href = '/sign-in';
  };

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative dropdown">
            <Listbox.Button className="relative cursor-default menu-user">
              <div className="user-avt">
                <img src="/images/icons/user.svg" alt="avt-default" />
              </div>

              <div className="user-name">{userInfo.email}</div>

              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option
                  className='relative cursor-default select-none py-2 pl-3 pr-9'
                  value="logout"
                >
                  <a href="/profile">
                    <div className="flex items-center cursor-pointer">
                      <span
                        className='ml-3 block truncate'
                      >
                        {t('my-profile')}
                      </span>
                    </div>
                  </a>
                </Listbox.Option>
                <Listbox.Option
                  className='relative cursor-default select-none py-2 pl-3 pr-9'
                  value="logout"
                >
                  <a href="/change-password">
                    <div className="flex items-center cursor-pointer">
                      <span
                        className='ml-3 block truncate'
                      >
                        {t('change-password')}
                      </span>
                    </div>
                  </a>
                </Listbox.Option>
                <Listbox.Option
                  className='relative cursor-default select-none py-2 pl-3 pr-9'
                  value="logout"
                >
                  <>
                    <div className="flex items-center cursor-pointer" onClick={handleLogout}>
                      <span
                        className='ml-3 block truncate'
                      >
                        {t('sign-out')}
                      </span>
                    </div>
                  </>
                </Listbox.Option>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
};

export default User;