import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './SportDetail.scss';

const SportAttendeesEditlUI = (props: any) => {
  const { t, AttendeesEditForm } = props;
  const breadcrumbItems = [
    {
      title: t('sport-attendees'),
      url: '/attendees/sports'
    },
    {
      title: t('sport-attendees-edit'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="sport-attendees-edit">
        <AttendeesEditForm
          t={t} />
      </div>
    </Desktop>
  )
}

export default SportAttendeesEditlUI;