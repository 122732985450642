import "./OrderDetail.scss";
import Breadcrumb from "@components/common/Breadcrumb";
import Mobile from "@layouts/mobile";

const OrderDetailUIMobile = (props: any) => {
  const { t, OrderForm } = props;

  const breadcrumbItems = [
    {
      title: t('orders-title'),
      url: '/orders'
    },
    {
      title: t('order-detail'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="order-detail-mobile">
        <OrderForm
          t={t}
          mobile={true} />
      </div>
    </Mobile>
  )
}

export default OrderDetailUIMobile;