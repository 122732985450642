import Desktop from '@layouts/desktop';
import './RoleDetail.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const RoleDetailUI = (props: any) => {
  const { t, RoleInformation } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('role-management'),
      url: '/organization/role'
    },
    {
      title: t('role-information'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="role-detail">
        <RoleInformation
          t={t}
        />
      </div>
    </Desktop>
  )
};

export default RoleDetailUI;