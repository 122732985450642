import Breadcrumb from "@components/common/Breadcrumb";
import './Coupon.scss';
import Mobile from "@layouts/mobile";

const CouponUIMobile = (props: any) => {
  const { t, CouponForm } = props;
  const breadcrumbItems = [
    {
      title: t('coupon-management'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="coupon-mobile">
        <CouponForm
          t={t}
          mobile={true}
        />
      </div>
    </Mobile>
  )
}

export default CouponUIMobile;