import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowWidth from '@helpers/InitWidthDevice';
import ResetPasswordUIDesktop from 'UI/desktop/ResetPassword';
import ResetPasswordUIMobile from 'UI/mobile/ResetPassword';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import Loader from '@components/common/Loader';
import Message from '@components/common/Message';
import UserInfo from '@helpers/Interface';
import ApiService from '@services/ApiService';

const FormResetPassword = (props: any) => {
  const { t, navigate, setLinkValid } = props;
  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false
  } as any);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [id, setId] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    setLoading(true);
    if (location && location.search && location.search !== '') {
      getDataResetPassword(location.search);
    }
  }, [location]);

  const getDataResetPassword = (query: string) => {
    const id_position = query.indexOf('user_id');
    const token_position = query.indexOf('&token');

    const user_id = query.slice(id_position + 8, token_position);
    const u_token = query.slice(token_position + 7);

    setId(user_id);
    setToken(u_token);
  };

  useEffect(() => {
    if (id !== '' && token !== '') {
      checkValidateLink();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  const checkValidateLink = async () => {
    try {
      const result = await ApiService.get(`auth/${id}/password/reset/verify?token=${token}`);

      if (result) {
        setLoading(false);
      }
    } catch (error: any) {
      setLinkValid(false);
    }

    setLoading(false);
  }

  const validatePassword = (value: string) => {
    let error;

    if (!value) {
      error = t('required-password');
    } else {
      if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{6,}/.test(value)) {
        error = t('wrong-format-password');
      }
    }

    return error;
  };

  const handleShowPassword = (value: boolean, type: string) => {
    const showPassValue = { ...showPass };

    showPassValue[type] = value;

    setShowPass({ ...showPassValue });
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);

    const submitData = {
      token: token,
      password: values.password
    };

    try {
      const result = await ApiService.put(`auth/${id}/password/reset`, submitData);

      if (result) {
        navigate('/sign-in');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ password: '', confirm_password: '' } as UserInfo}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
      validate={(values) => {
        const errors = {
          confirm_password: ''
        };
        if (values.password !== values.confirm_password) {
          errors.confirm_password = t('password-not-match');
          return errors;
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-item">
            <div className="form-label">{t('new-password')}</div>

            <Field name="password" validate={validatePassword}>
              {({
                field,
                meta,
              }: any) => (
                <div className="field-group">
                  <div className="pass-group">
                    <input {...field} type={showPass.password ? "text" : "password"} className="input" placeholder={t('enter-new-password')} autoComplete="off" />

                    {
                      showPass.password ?
                        <EyeIcon className="h-4 w-4 icon-show-pass-desk" onClick={() => handleShowPassword(false, 'password')} />
                        :
                        <EyeSlashIcon className="h-4 w-4 icon-show-pass-desk" onClick={() => handleShowPassword(true, 'password')} />
                    }
                  </div>


                  {meta.touched && meta.error && (
                    <div className="field-error">{meta.error}</div>
                  )}
                </div>
              )}
            </Field>
          </div>

          <div className="form-item">
            <div className="form-label">{t('confirm-password')}</div>

            <Field name="confirm_password" validate={validatePassword}>
              {({
                field,
                meta,
              }: any) => (
                <div className="field-group">
                  <div className="pass-group">
                    <input {...field} type={showPass.confirm_password ? "text" : "password"} className="input" placeholder={t('confirm-password')} autoComplete="off" />

                    {
                      showPass.confirm_password ?
                        <EyeIcon className="h-4 w-4 icon-show-pass-desk" onClick={() => handleShowPassword(false, 'confirm_password')} />
                        :
                        <EyeSlashIcon className="h-4 w-4 icon-show-pass-desk" onClick={() => handleShowPassword(true, 'confirm_password')} />
                    }
                  </div>


                  {meta.touched && meta.error && (
                    <div className="field-error">{meta.error}</div>
                  )}
                </div>
              )}
            </Field>
          </div>

          <button type="submit" disabled={isSubmitting} className="btn-submit">
            {t('btn-submit')}
          </button>

          {
            message !== '' &&
            <Message
              message={message}
              messageType={messageType}
              setMessage={setMessage}
            />
          }

          {
            loading &&
            <Loader />
          }
        </Form>
      )}
    </Formik>
  )
  
}

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 1024) {
    return (
      <ResetPasswordUIDesktop
        FormResetPassword={FormResetPassword}
        t={t}
        navigate={navigate}
      />
    )
  } else {
    return (
      <ResetPasswordUIMobile
        FormResetPassword={FormResetPassword}
        t={t}
        navigate={navigate}
      />
    )
  }
}

export default ResetPassword;