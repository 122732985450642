import { useEffect, useState } from 'react';
import useWindowWidth from '@helpers/InitWidthDevice';
import AccountManagementUI from 'UI/desktop/Organization/AccountManagement';
import AccountManagementUIMobile from 'UI/mobile/Organization/AccountManagement';
import {
  Button, Typography, Switch, Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Select, Option } from "@material-tailwind/react";
import { EyeIcon, PencilIcon } from '@heroicons/react/20/solid';
import Pagination from '@components/common/Pagination';
import { useTranslation } from 'react-i18next';
import ApiService from '@services/ApiService';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import DialogConfirm from '@components/common/DialogConfirm';
import DateRangePicker from '@components/common/DateRangePicker';
import moment from 'moment';

const FilterGroup = (props: any) => {
  const { isMobile, openSearchBox, setOpenSearchBox, t, search, setSearch, setFilter, setMessage, setMessageType, setApplyDate, setPage, applyDate } = props;

  const [roleList, setRoleList] = useState<Object[]>([]);

  const [role, setRole] = useState('');

  const status_list = [
    {
      value: '',
      label: t('all-status')
    },
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('in-active')
    },
  ];

  const [status, setStatus] = useState('');
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (search.status !== '') {
      status_list.forEach((item: any) => {
        if (item.value.toString() === search.status) {
          setStatus(item.value);
        }
      });
    } else {
      setStatus('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (roleList?.length > 0) {
      if (search.role_id !== '') {
        roleList.forEach((item: any) => {
          if (item.value === search.role_id) {
            setRole(item.value);
          }
        });
      } else {
        setRole('');
      }
    }
  }, [search, roleList]);

  useEffect(() => {
    const intervalFetchData = setInterval(() => {
      setFetchData(true);
      clearInterval(intervalFetchData);
    }, 100);
  }, []);

  useEffect(() => {
    if (fetchData) {
      fetchListRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchListRole = async () => {
    try {
      const result = await ApiService.get(`role/all/ids`);

      if (result) {
        const roles = [
          {
            value: '',
            label: t('all-role-name')
          }
        ] as any;
        result.forEach((item: any) => {
          const obj = {
            value: item.id,
            label: item.name
          };

          roles.push(obj);
        });

        setFilter(true);
        setRoleList([...roles]);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
  }

  const handleChangeSearchValue = (value: any, type: string) => {
    const values = { ...search } as any;

    values[type] = value;

    if (type === 'status') {
      setStatus(value);
    }

    if (type === 'role_id') {
      setRole(value);
    }

    setSearch({ ...values });
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setFilter(true);
    }
  };

  if (!isMobile) {
    return (
      <div className="filter-group" onKeyDown={handleKeyDown}>
        <div className="filter-item">
          <div className="filter-label">{t('search')}</div>

          <input className="input-search"
            onChange={(e: any) => handleChangeSearchValue(e.target.value, 'q')}
            placeholder={t('placeholder-search-account')}
            value={search.q} />
        </div>

        <div className="filter-item date-picker">
          <div className="filter-label">{t('created-date')}</div>

          <DateRangePicker
            quickSelectDate={true}
            applyDate={applyDate}
            setApplyDate={setApplyDate}
          />
        </div>

        <div className="filter-item">
          <div className="filter-label">{t('role-name')}</div>

          <div className='select-role'>
            {
              roleList.length > 0 &&
              <Select
                value={role}
                onChange={(val) => handleChangeSearchValue(val, 'role_id')}
                placeholder="Select role account"
                onPointerEnterCapture="none" onPointerLeaveCapture="none"
              >
                {
                  roleList.map((item: any, index: number) => {
                    return (
                      <Option key={`${item.value}_${index}`}
                        value={item.value}>{item.label}</Option>
                    )
                  })
                }
              </Select>
            }

          </div>

        </div>

        <div className="filter-item">
          <div className="filter-label">{t('status')}</div>

          <div className='select-role'>
            <Select
              value={status}
              onChange={(val) => handleChangeSearchValue(val, 'status')}
              placeholder="Select status"
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
            >
              {
                status_list.map((item: any, index: number) => {
                  return (
                    <Option key={`${item.value}_${index}`}
                      value={item.value}>{item.label}</Option>
                  )
                })
              }
            </Select>
          </div>
        </div>

        <Button
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          placeholder="button filter account" className="btn-search" onClick={() => {
            setFilter(true)
            setPage(1);
          }}>
          <MagnifyingGlassIcon className="w-5 h-5 mr-2 icon-search" />

          <span className="btn-title">{t('search')}</span>
        </Button>
      </div>
    )
  } else {
    return (
      <Popover placement="bottom" open={openSearchBox} handler={setOpenSearchBox}>
        <PopoverHandler onPointerEnterCapture="none" onPointerLeaveCapture="none">
          <div className="btn-search">
            <MagnifyingGlassIcon className="w-7 h-7" />
          </div>
        </PopoverHandler>
        <PopoverContent
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          className="w-96 popup-search" placeholder="PopoverContent">
          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('search')}
          </Typography>

          <input className="input-search"
            placeholder={t("placeholder-search-account")}
            onChange={(e: any) => handleChangeSearchValue(e.target.value, 'q')}
            value={search.q} />

          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('created-at')}
          </Typography>

          <div className="date-picker">
            <DateRangePicker
              quickSelectDate={true}
              mobile={isMobile}
              applyDate={applyDate}
              setApplyDate={setApplyDate}
            />
          </div>

          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('role-name')}
          </Typography>

          <div className='select-role'>
            {
              roleList.length > 0 &&
              <Select
                value={role}
                onChange={(val) => handleChangeSearchValue(val, 'role_id')}
                placeholder="Select role account"
                onPointerEnterCapture="none" onPointerLeaveCapture="none"
              >
                {
                  roleList.map((item: any, index: number) => {
                    return (
                      <Option key={`${item.value}_${index}`}
                        value={item.value}>{item.label}</Option>
                    )
                  })
                }
              </Select>
            }

          </div>

          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('status')}
          </Typography>

          <div className='select-role'>
            <Select
              value={status}
              onChange={(val) => handleChangeSearchValue(val, 'status')}
              placeholder="Select status"
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
            >
              {
                status_list.map((item: any, index: number) => {
                  return (
                    <Option key={`${item.value}_${index}`}
                      value={item.value}>{item.label}</Option>
                  )
                })
              }
            </Select>
          </div>

          <Button
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            placeholder="button filter account" className="btn-search" onClick={() => {
              setOpenSearchBox(false);
              setFilter(true);
              setPage(1);
            }}>
            <MagnifyingGlassIcon className="w-5 h-5 mr-2 icon-search" />

            <span className="btn-title">{t('search')}</span>
          </Button>
        </PopoverContent>
      </Popover>
    )
  }
}

const Dashboard = (props: any) => {
  const { isMobile, setFilter, t, filter, search, message, setMessage, messageType, setMessageType, loading, setLoading, applyDate, page, setPage } = props;
  const [paging, setPaging] = useState(null as any);
  const [accounts, setAccounts] = useState([] as any);
  const TABLE_HEAD = [t('no'), t('full-name'), "Email", t('role-name'), t('created-at'), t('created-by'), t('status'), t('action')];
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [activeAccount, setActiveAccount] = useState({
    is_active: false,
    user_id: ''
  });
  const [submitAction, setSubmitAction] = useState(false);

  useEffect(() => {
    if (filter) {
      fetchAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchAccount = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`account/page/${page}?q=${search.q}&gte=${applyDate.start}&lte=${applyDate.end}&is_active=${search.status}&role_id=${search.role_id}&limit=10`);

      if (result) {
        setPaging(result.paging);
        setAccounts(result.users);
        setFilter(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleConfirmAction = async (is_active: boolean, user_id: string) => {
    setOpen(true);

    is_active ? setDialogMessage(t('confirm-active-account')) : setDialogMessage(t('confirm-inactive-account'));
    const activeData = {
      user_id,
      is_active
    };

    setActiveAccount(activeData);
  };

  useEffect(() => {
    async function handleActiveAccount() {
      try {
        const result = await ApiService.put(`account/${activeAccount.user_id}/active/${activeAccount.is_active}`, activeAccount);

        if (result) {
          setSubmitAction(false);
          const account_temp = [...accounts];
          account_temp.forEach((item: any) => {
            if (item.id === activeAccount.user_id) {
              item.is_active = activeAccount.is_active;
            }
          });
          setAccounts([...account_temp]);
          setMessage(t('update-success'));
          setMessageType('success');
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setSubmitAction(false);
      }
    }

    if (submitAction) {
      handleActiveAccount();
    }
  }, [submitAction, activeAccount, t, accounts, setMessage, setMessageType]);

  if (!isMobile) {
    return (
      <div className="dashboard">
        <div className="h-full w-full overflow-x-auto overflow-y-hidden">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, i) => (
                  <th
                    key={head}
                    className="content-table"
                  >
                    <Typography
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      variant="small"
                      className={(i === 0) ? "leading-none stt" : "leading-none"}
                      placeholder="Typography"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                accounts.length > 0 &&
                accounts.map((item: any, index: number) => {
                  const isLast = index === accounts.length - 1;
                  const classes = isLast ? "content-table" : "border-b border-blue-gray-50 content-table";

                  return (
                    <tr key={`account_${item.id}_${index}`} className='table-row'>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          className='stt'
                          placeholder="Typography"
                        >
                          {
                            index < 9 &&
                            <span>{paging.current_page - 1}{index + 1}</span>
                          }

                          {
                            index === 9 &&
                            <span>{paging.current_page}0</span>
                          }
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          placeholder="Typography"
                        >
                          {item.first_name} {item.last_name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          placeholder="Typography"
                        >
                          {item.email}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          placeholder="Typography"
                        >
                          {item.role_name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          placeholder="Typography"
                        >
                          {moment(item.created).format('DD/MM/YYYY')}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          placeholder="Typography"
                        >
                          {item.created_by}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          variant="small"
                          placeholder="Typography"
                        >
                          <Switch
                            checked={item.is_active}
                            onChange={() => handleConfirmAction(!item.is_active, item.id)}
                            crossOrigin="switch"
                            ripple={false}
                            className={(item.role_name.toLowerCase() === 'owner') ? "h-full w-full checked:bg-[#8AD8FF] switch" : "h-full w-full checked:bg-[#0162D1] switch"}
                            containerProps={{
                              className: "w-11 h-6",
                            }}
                            circleProps={{
                              className: "before:hidden left-0.5 border-none",
                            }}
                            disabled={(item.role_name.toLowerCase() === 'owner') ? true : false}
                            onPointerEnterCapture="none" onPointerLeaveCapture="none" />
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="action">
                          <a href={`/organization/account/${item.id}/detail`}>
                            <div className="btn-detail">
                              <EyeIcon className="w-4 h-4" />
                            </div>
                          </a>
                          {
                            (item.role_name.toLowerCase() !== 'owner') &&
                            <a href={`/organization/account/${item.id}/edit`}>
                              <div className="btn-detail">
                                <PencilIcon className="w-4 h-4" />
                              </div>
                            </a>
                          }

                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {
          paging && paging.total_page > 1 &&
          <Pagination
            setPage={setPage}
            paging={paging}
            setFilter={setFilter}
          />
        }

        <DialogConfirm
          open={open}
          setOpen={setOpen}
          setSubmitAction={setSubmitAction}
          dialogMessage={dialogMessage}
        />

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  } else {
    return (
      <div className="dashboard">
        {
          accounts.length > 0 && accounts.map((item: any, index: number) => {
            return (
              <div className="account-item" key={`account_${item.email}_${index}`}>
                <div className="account-head">
                  <Switch
                    checked={item.is_active}
                    onChange={() => handleConfirmAction(!item.is_active, item.id)}
                    crossOrigin="switch"
                    ripple={false}
                    className={(item.role_name.toLowerCase() === 'owner') ? "h-full w-full checked:bg-[#8AD8FF] switch" : "h-full w-full checked:bg-[#0162D1] switch"}
                    containerProps={{
                      className: "w-11 h-6",
                    }}
                    circleProps={{
                      className: "before:hidden left-0.5 border-none",
                    }}
                    disabled={(item.role_name.toLowerCase() === 'owner') ? true : false}
                    onPointerEnterCapture="none" onPointerLeaveCapture="none" />

                  <div className="account-head-right">
                    <a href={`/organization/account/${item.id}/detail`}>
                      <div className="btn-detail">
                        <EyeIcon className="w-4 h-4" />
                      </div>
                    </a>

                    {
                      (item.role_name.toLowerCase() !== 'owner') &&
                      <a href={`/organization/account/${item.id}/edit`}>
                        <div className="btn-detail">
                          <PencilIcon className="w-4 h-4" />
                        </div>
                      </a>
                    }

                  </div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('no')}</div>
                  <div className="account-info-value">
                    {
                      index < 9 &&
                      <span>{paging.current_page - 1}{index + 1}</span>
                    }

                    {
                      index === 9 &&
                      <span>{paging.current_page}0</span>
                    }
                  </div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('full-name')}</div>
                  <div className="account-info-value">{item.first_name} {item.last_name}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">Email</div>
                  <div className="account-info-value">{item.email}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('role-name')}</div>
                  <div className="account-info-value">{item.type}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('created-at')}</div>
                  <div className="account-info-value">{moment(item.created).format('DD/MM/YYYY')}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('created-by')}</div>
                  <div className="account-info-value">{item.created_by}</div>
                </div>
              </div>
            )
          })
        }

        {
          paging && paging.total_page > 1 &&
          <Pagination
            setPage={setPage}
            paging={paging}
            setFilter={setFilter}
          />
        }

        <DialogConfirm
          open={open}
          setOpen={setOpen}
          setSubmitAction={setSubmitAction}
          dialogMessage={dialogMessage}
        />

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  }

}

const AccountManagement = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <AccountManagementUI
        FilterGroup={FilterGroup}
        Dashboard={Dashboard}
        t={t}
      />
    )
  } else {
    return (
      <AccountManagementUIMobile
        FilterGroup={FilterGroup}
        Dashboard={Dashboard}
        t={t}
      />
    )
  }
};

export default AccountManagement;