import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import {useEffect} from 'react';
import SignIn from 'pages/SignIn';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import ChangePassword from "pages/ChangePassword";
import OrganizationInformation from "pages/Organization/Information";
import EditOrganizationInformation from "pages/Organization/Information/Edit";
import AccountManagement from '@pages/Organization/AccountManagement';
import CreateAccountManagement from "@pages/Organization/AccountManagement/Create";
import AccountDetail from "@pages/Organization/AccountManagement/Detail";
import EditAccount from "@pages/Organization/AccountManagement/Edit";
import RoleManagement from "@pages/Organization/RoleManagement";
import CreateRoleManagement from "@pages/Organization/RoleManagement/Create";
import RoleDetail from "@pages/Organization/RoleManagement/Detail";
import EditRoleManagement from "@pages/Organization/RoleManagement/Edit";
import Profile from "@pages/Profile";
import EditProfile from "@pages/Profile/Edit";
import Dashboard from "@pages/Dashboard";
import Coupon from "@pages/Coupon";
import Orders from "@pages/Orders";
import OrderDetail from "@pages/Orders/Detail";
import SportAttendees from "@pages/Attendees/Sport";
import AttractionAttendees from "@pages/Attendees/Attraction";
import SportAttendeesDetail from "@pages/Attendees/Sport/Detail";
import AttractionAttendeesDetail from "@pages/Attendees/Attraction/Detail";
import EditOrder from "@pages/Orders/Edit";
import SportAttendeesEdit from "@pages/Attendees/Sport/Edit";
import AttractionAttendeesEdit from "@pages/Attendees/Attraction/Edit";

const RoutesList = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<RedirectToDashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/organization/info" element={<OrganizationInformation />} />
      <Route path="/organization/info/edit" element={<EditOrganizationInformation />} />
      <Route path="/organization/account" element={<AccountManagement />} />
      <Route path="/organization/account/create" element={<CreateAccountManagement />} />
      <Route path="/organization/account/:slug/detail" element={<AccountDetail />} />
      <Route path="/organization/account/:slug/edit" element={<EditAccount />} />
      <Route path="/organization/role" element={<RoleManagement />} />
      <Route path="/organization/role/create" element={<CreateRoleManagement />} />
      <Route path="/organization/role/:slug" element={<RoleDetail />} />
      <Route path="/organization/role/:slug/edit" element={<EditRoleManagement />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/edit" element={<EditProfile />} />
      <Route path="/coupon" element={<Coupon />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:slug/detail" element={<OrderDetail />} />
      <Route path="/orders/:slug/edit" element={<EditOrder />} />
      <Route path="/attendees/sports" element={<SportAttendees />} />
      <Route path="/attendees/attractions" element={<AttractionAttendees />} />
      <Route path="/attendees/sports/:slug/detail" element={<SportAttendeesDetail />} />
      <Route path="/attendees/attractions/:slug/detail" element={<AttractionAttendeesDetail />} />
      <Route path="/attendees/sports/:slug/edit" element={<SportAttendeesEdit />} />
      <Route path="/attendees/attractions/:slug/edit" element={<AttractionAttendeesEdit />} />
    </Routes>
  </BrowserRouter>
);

const RedirectToDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/dashboard', { replace: true });
  }, [navigate]);
  return null;
};


export default RoutesList;