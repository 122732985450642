import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import './DialogNotification.scss';

const DialogNotification = (props: any) => {
  const { open, setOpen, dialogMessage } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      handler={() => setOpen(!open)}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
      placeholder="dialog notification action"
      id="dialog-notification"
      onPointerEnterCapture="none" onPointerLeaveCapture="none"
    >
      <DialogHeader
        onPointerEnterCapture="none" onPointerLeaveCapture="none"
        placeholder="dialog confirm action header">{t('notification')}</DialogHeader>
      <DialogBody
        onPointerEnterCapture="none" onPointerLeaveCapture="none"
        placeholder="dialog confirm action body">
        {dialogMessage}
      </DialogBody>
      <DialogFooter
        onPointerEnterCapture="none" onPointerLeaveCapture="none"
        placeholder="dialog confirm action footer">
        <Button
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          placeholder="Button cancel action" className="btn-cancel" onClick={() => setOpen(!open)}>{t('btn-close')}</Button>
      </DialogFooter>
    </Dialog>
  )
}

export default DialogNotification;