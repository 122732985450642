import Desktop from '@layouts/desktop';
import './ResetPasswordUI.scss';
import Language from '@components/common/Language';
import { useState } from 'react';

const ResetPasswordUIDesktop = (props: any) => {
  const { t, navigate, FormResetPassword } = props;
  const [linkValid, setLinkValid] = useState(true);

  if (linkValid) {
    return (
      <Desktop>
        <div id="reset-password">
          <div className="container">
            <div className="form">
              <div className="form-body">
                <div className="header">
                  <img
                    src='/images/icons/logo.png'
                    className="logo"
                    alt="logo-company" />

                  <Language />
                </div>

                <div className="label">{t('reset-password')}</div>

                <div className="form-group">
                  <FormResetPassword
                    t={t}
                    navigate={navigate}
                    setLinkValid={setLinkValid}
                  />
                </div>

                <div className="btn-back-group">
                  {t('back-to')}
                  <div className="btn-back" onClick={() => navigate('/sign-in')}>{t('sign-in')}</div>
                </div>
              </div>
            </div>

            <img
              src='/images/sign-in-background.png'
              className="img-background"
              alt="background-sign-in-page" />
          </div>
        </div>
      </Desktop>
    )
  } else {
    return (
      <div className='link-invalid'>
        <img src="/images/icons/logo.png" className='logo-company' alt='logo' />

        <div className="link-invalid-title">{t('link-invalid-title')}</div>
        <div className="link-invalid-content" dangerouslySetInnerHTML={{ __html: t('link-invalid-content') }}></div>

        <a href="/forgot-password">
          <div className="btn-forgor-password">{t('forgot-password')}</div>
        </a>
      </div>
    )
  }

}

export default ResetPasswordUIDesktop;