import Breadcrumb from "@components/common/Breadcrumb";
import './Sport.scss';
import Mobile from "@layouts/mobile";

const SportAttendeesUIMobile = (props: any) => {
  const { t, SportAttendeesForm } = props;
  const breadcrumbItems = [
    {
      title: t('attendees'),
      url: ''
    },
    {
      title: t('sport-attendees'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="sport-attendees-mobile">
        <SportAttendeesForm
          t={t}
          mobile={true} />
      </div>
    </Mobile>
  )
}

export default SportAttendeesUIMobile;