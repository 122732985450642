import { Spinner } from "@material-tailwind/react";
import './loader.scss';

const Loader = () => {
  return (
    <div id="loader">
      <Spinner
        className="h-20 w-20"
        color="blue"
        onPointerEnterCapture="none" onPointerLeaveCapture="none" />
    </div>
  )
}

export default Loader;