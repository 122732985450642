import Desktop from "@layouts/desktop";
import "./OrderDetail.scss";
import Breadcrumb from "@components/common/Breadcrumb";

const OrderDetailUI = (props: any) => {
  const { t, OrderForm } = props;

  const breadcrumbItems = [
    {
      title: t('orders-title'),
      url: '/orders'
    },
    {
      title: t('order-detail'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="order-detail">
        <OrderForm
          t={t}
        />
      </div>
    </Desktop>
  )
}

export default OrderDetailUI;