import Loader from "@components/common/Loader";
import Message from "@components/common/Message";
import Pagination from "@components/common/Pagination";
import useWindowWidth from "@helpers/InitWidthDevice";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Button, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import ApiService from "@services/ApiService";
import SportAttendeesUI from "UI/desktop/Attendees/Sport";
import SportAttendeesUIMobile from "UI/mobile/Attendees/Sport";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';

const SportAttendeesForm = (props: any) => {
  const { t, mobile } = props;
  const [q, setQ] = useState('');
  const [events, setEvents] = useState([] as any);
  const [event, setEvent] = useState(null as any);
  const [eventID, setEventID] = useState('');
  const [categories, setCategories] = useState([] as any);
  const [category, setCategory] = useState(null as any);
  const [categoryID, setCategoryID] = useState('');
  const [loading, setLoading] = useState(false);
  const { i18n: { language } = {} } = useTranslation();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [filter, setFilter] = useState(false);
  const [paging, setPaging] = useState(null as any);
  const [page, setPage] = useState(1);
  const [attendees, setAttendees] = useState([] as any);
  const [openFilter, setOpenFilter] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const TABLE_HEAD = [t('no'), t('full-name'), "Email", t('event-name'), t('category'), t('phone'), t('id-card'), t('order-code'), t('action')];

  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const [initParams, setInitParams] = useState(true);

  useEffect(() => {
    if (initParams) {
      const keys = Object.keys(paramsObject);

      if (keys?.length > 0) {
        handleInitSearchValue();
      } else {
        setFilter(true);
        setInitParams(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);

  const handleInitSearchValue = () => {
    if (paramsObject.q && paramsObject.q !== '') {
      setQ(paramsObject.q);
    }

    if (paramsObject.event_id && paramsObject.event_id !== '') {
      setEventID(paramsObject.event_id);
    }

    if (paramsObject.category && paramsObject.category !== '') {
      setCategoryID(paramsObject.category);
    }

    const intervalInit = setInterval(() => {
      setFilter(true);
      setInitParams(false);
      clearInterval(intervalInit);
    }, 1000);

  }

  useEffect(() => {
    handleUpdateRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, eventID, categoryID]);

  const handleUpdateRoute = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('q', q);
    newSearchParams.set('event_id', eventID);
    newSearchParams.set('category', categoryID);

    const newParamsObject = Object.fromEntries([...newSearchParams]);

    const keys = Object.keys(newParamsObject);
    let changeRoute = false;

    if (keys?.length > 0) {
      keys?.forEach((key: string) => {
        if (newParamsObject[key] !== '') {
          changeRoute = true;
        }
      });
    }

    if (changeRoute || !initParams) {
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  }

  useEffect(() => {
    if (!initParams) {
      if (events?.length > 0) {
        if (eventID !== '') {
          events.forEach((item: any) => {
            if (item?.value === eventID) {
              setEvent(item);
            }
          })
        } else {
          setEvent(events[0]);
        }
      }

      if (categories?.length > 0) {
        if (eventID !== '') {
          categories.forEach((item: any) => {
            if (item?.value === categoryID) {
              setCategory(item);
            }
          })
        } else {
          setCategory(categories[0]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams, categories]);

  useEffect(() => {
    const intervalFetchData = setInterval(() => {
      setFetchData(true);
      clearInterval(intervalFetchData);
    }, 100);
  }, []);

  useEffect(() => {
    if (fetchData) {
      fetchEventList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchEventList = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`events?type=sports`);

      if (result && result.length > 0) {
        const arr = [
          {
            value: '',
            label: t('all')
          }
        ] as any;

        result.forEach((item: any) => {
          if (item.id !== 'all') {
            const obj = {
              value: item.id,
              label: get(item, `name_${language}`, '')
            };

            arr.push(obj);
          }
        });

        setEvent(arr[0]);
        setEvents(arr);
        setFilter(true);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);

  }

  useEffect(() => {
    if (eventID !== '') {
      setCategory(null);
      fetchCategories();
    } else {
      setCategory(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID, language]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`events/${eventID}/categories`);

      if (result && result.length > 0) {
        const arr = [
          {
            value: '',
            label: t('all')
          }
        ] as any;

        result.forEach((item: any) => {
          const obj = {
            value: item.category_en,
            label: get(item, `category_${language}`, '')
          };

          arr.push(obj);
        });

        setCategory(arr[0]);
        setCategories(arr);
      } else {
        setCategories([]);
        setCategory({
          value: '',
          label: ''
        });
        setLoading(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
    setLoading(false);
  }

  useEffect(() => {
    async function fetchAttendees() {
      setLoading(true);

      try {
        const result = await ApiService.get(`participants/page/${page}?limit=10&q=${q}&event_id=${eventID}&category=${categoryID}`);

        if (result) {
          setPaging(result.paging);
          setAttendees(result.participants);
          setFilter(false);
          setLoading(false);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }

    }

    if (filter && !initParams) {
      fetchAttendees();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, q, filter, category, event, initParams]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setFilter(true);
    }
  };

  const handleExportData = async () => {
    setLoading(true);

    try {
      const result = await ApiService.get(`exports/participants?q=${q}&event_id=${event.value}&category=${categoryID}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  if (!mobile) {
    return (
      <div className="attendees-form" onKeyDown={handleKeyDown}>
        <div className="attendees-form__header">
          <div className="header-title">{t('sport-attendees')}</div>

          <div className="flex items-center justify-center text-white bg-[#011BB6] h-[48px] px-[12px] rounded-[4px] cursor-pointer"
            onClick={handleExportData}>
            <ArrowDownIcon className="w-5 h-5 mr-[5px]" />
            <span>{t('btn-export-participants')}</span>
          </div>
        </div>

        <div className="attendees-form__body">
          <div className="attendees-form__filter-group">
            <div className="filter-item search">
              <div className="filter-label">{t('search')}</div>

              <input type="text"
                className="input-search"
                placeholder={t('placeholder-search-sport-attendees')}
                value={q}
                onChange={(e: any) => setQ(e.target.value)} />
            </div>

            <div className="filter-item events">
              <div className="filter-label">{t('event-name')}</div>

              {
                (event) &&
                <Select
                  value={event}
                  name="events"
                  options={events}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={events[0].label}
                  onChange={(e: any) => {
                    setEvent(e);
                    setEventID(e.value);
                  }} />
              }

            </div>

            {
              (categories.length > 0 && category) &&
              <div className="filter-item categories">
                <div className="filter-label">{t('category')}</div>

                <Select
                  value={category}
                  name="categories"
                  options={categories}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={categories[0].label}
                  onChange={(e: any) => {
                    setCategory(e);
                    setCategoryID(e.value)
                  }} />

              </div>
            }

            <Button
              placeholder="Button search coupon"
              className="btn-search"
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
              onClick={() => {
                setFilter(true);
                setPage(1);
              }}>
              <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
              <span>{t('search')}</span>
            </Button>

          </div>

          <div className="attendees-form__board">
            <div className="h-full w-full overflow-scroll overflow-y-hidden">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="p-4"
                      >
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {head}

                          {/* {
                            head === t('status') &&
                            <Tooltip content={<span dangerouslySetInnerHTML={{ __html: t('tooltip-status') }} />} placement="left">
                              <img src="/images/icons/tooltip.svg" className="icon-tooltip" alt="icon-tooltip" />
                            </Tooltip>
                          } */}
                        </Typography>

                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {
                    attendees.length > 0 &&
                    attendees.map((item: any, index: number) => {
                      const isLast = index === attendees.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={`event_${item.code}_${index}`} className="table-row">
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {
                                index < 9 &&
                                <span>{paging.current_page - 1}{index + 1}</span>
                              }

                              {
                                index === 9 &&
                                <span>{paging.current_page}0</span>
                              }
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal table-link"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              <a href={`/attendees/sports/${item.participant_id}/detail`} className="no-underline">
                                {item.full_name}
                              </a>
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              {item.email}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              {get(item, `event_name_${language}`, '')}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              {item.category_name_vi}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              {item.phone}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              {item.id_card}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              {item.order_code}
                            </Typography>
                          </td>
                          <td className={classes}>
                            {
                              item.status === 1 &&
                              <a href={`/attendees/sports/${item.participant_id}/edit`}>
                                <div className="w-[32px] h-[32px] rounded-[4px] bg-[#F5F6F7] flex items-center justify-center cursor-pointer">
                                  <img alt="icon-edit" src="/images/icons/icon-edit.svg" />
                                </div>
                              </a>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {
              paging &&
              <div className="board__bottom">
                <div className="board__bottom__total-item">{t('total')}: {paging.total_item}/{paging.total}</div>

                {
                  paging.total_page > 1 &&
                  <Pagination
                    setPage={setPage}
                    paging={paging}
                    setFilter={setFilter}
                  />
                }
              </div>
            }
          </div>
        </div>

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  } else {
    return (
      <div className="attendees-form" onKeyDown={handleKeyDown}>
        <div className="attendees-form__filter">
          <div className="filter__header">
            <div className="filter__header__label">{t('sport-attendees')}</div>

            <div className="flex items-center">
              <Popover placement="bottom" open={openFilter} handler={setOpenFilter}>
                <PopoverHandler>
                  <MagnifyingGlassIcon className="w-[24px] h-[24px]" />
                </PopoverHandler>
                <PopoverContent onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  className="w-96 popup-search-coupon" placeholder="PopoverContent">
                  <div className="filter-item">
                    <div className="filter-item__label">{t('search')}</div>

                    <input type="text" className="filter-item__input"
                      placeholder={t('placeholder-search-sport-attendees-mobile')}
                      onChange={(e: any) => setQ(e.target.value)}
                      value={q} />
                  </div>

                  <div className="filter-item events">
                    <div className="filter-label">{t('event-name')}</div>

                    {
                      (events.length > 0 && event) &&
                      <Select
                        value={event}
                        name="events"
                        options={events}
                        className="filter-item__select"
                        classNamePrefix="select"
                        placeholder={events[0].label}
                        onChange={(e: any) => {
                          setEvent(e);
                          setCategory(null);
                          setEventID(e.value);
                        }} />
                    }

                  </div>

                  {
                    (categories.length > 0 && category) &&
                    <div className="filter-item categories">
                      <div className="filter-label">{t('category')}</div>

                      <Select
                        value={category}
                        name="categories"
                        options={categories}
                        className="filter-item__select"
                        classNamePrefix="select"
                        placeholder={categories[0].label}
                        onChange={(e: any) => {
                          setCategoryID(e.value);
                          setCategory(e)
                        }} />

                    </div>
                  }

                  <Button
                    placeholder="Button search coupon"
                    className="btn-search"
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    onClick={() => {
                      setFilter(true);
                      setOpenFilter(false);
                      setPage(1);
                    }}>
                    <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
                    <span>{t('search')}</span>
                  </Button>

                </PopoverContent>
              </Popover>

              <div className="w-[48px] h-[48px] bg-[#011BB6] flex items-center justify-center cursor-pointer rounded-[4px] ml-[30px]"
                onClick={handleExportData}>
                <ArrowDownIcon className="w-[32px] h-[32px] text-white font-bold" />
              </div>
            </div>
          </div>

        </div>

        <div className={openFilter ? "attendees-form__body overlay" : "attendees-form__body"}>
          {
            attendees.map((item: any, index: number) => {
              return (
                <div className="attendees-item" key={`attendees-item-${index}`}>
                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('action')}</div>

                    <div className="flex items-center w-[100%] justify-end">
                      <div className="w-[32px] h-[32px] flex items-center justify-center bg-[#F5F6F7] rounded-[4px]" onClick={() => {
                        window.location.href = `/attendees/sports/${item.participant_id}/detail`
                      }}>
                        <EyeIcon className="w-[20px] h-[20px] text-[#262626]" />
                      </div>

                      <div className="w-[32px] h-[32px] flex items-center justify-center bg-[#F5F6F7] rounded-[4px] ml-[12px]" onClick={() => {
                        window.location.href = `/attendees/sports/${item.participant_id}/edit`
                      }}>
                        <img src="/images/icons/icon-edit.svg" alt="icon-edit" />
                      </div>
                    </div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('no')}</div>
                    <div className="attendees-item__row__value">{
                      index < 9 &&
                      <span>{paging.current_page - 1}{index + 1}</span>
                    }

                      {
                        index === 9 &&
                        <span>{paging.current_page}0</span>
                      }</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('full-name')}</div>
                    <div className="attendees-item__row__value">{item.full_name}</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('email')}</div>
                    <div className="attendees-item__row__value">{item.email}</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('event-name')}</div>
                    <div className="attendees-item__row__value">{get(item, `event_name_${language}`, '')}</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('category')}</div>
                    <div className="attendees-item__row__value">{item.category_name_vi}</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('phone')}</div>
                    <div className="attendees-item__row__value">{item.phone}</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('id-card')}</div>
                    <div className="attendees-item__row__value">{item.id_card}</div>
                  </div>

                  <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('order-code')}</div>
                    <div className="attendees-item__row__value">{item.order_code}</div>
                  </div>

                  {/* <div className="attendees-item__row">
                    <div className="attendees-item__row__label">{t('status')}</div>
                    <div className="attendees-item__row__value">
                      {
                        item.status === 1 ?
                          <span>{t('active')}</span>
                          :
                          <span>{t('in-active')}</span>
                      }
                    </div>
                  </div> */}
                </div>
              )
            })
          }
        </div>

        {
          paging && paging.total_page > 1 &&
          <Pagination
            setPage={setPage}
            paging={paging}
            setFilter={setFilter}
          />
        }

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  }

}

const SportAttendees = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <SportAttendeesUI
        t={t}
        SportAttendeesForm={SportAttendeesForm}
      />
    )
  } else {
    return (
      <SportAttendeesUIMobile
        t={t}
        SportAttendeesForm={SportAttendeesForm}
      />
    )
  }
}

export default SportAttendees;