import Loader from "@components/common/Loader";
import Message from "@components/common/Message";
import useWindowWidth from "@helpers/InitWidthDevice";
import ApiService from "@services/ApiService";
import SportAttendeesDetailUI from "UI/desktop/Attendees/Sport/Detail";
import SportAttendeesDetailUIMobile from "UI/mobile/Attendees/Sport/Detail";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const AttendeesDetailForm = (props: any) => {
  const { t, mobile } = props;
  const location = useLocation();
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [generalInfo, setGeneralInfo] = useState(null as any);
  const [templateData, setTemplateData] = useState(null as any);
  const [sunmaryInfo, setSummaryInfo] = useState(null as any);
  const { i18n: { language } = {} } = useTranslation();

  useEffect(() => {
    if (location && location.pathname && location.pathname !== '') {
      const start = location.pathname.indexOf('sports/');
      const end = location.pathname.indexOf('/detail');

      if (start !== -1 && end !== -1) {
        const attendees_id = location.pathname.slice(start + 7, end);

        setId(attendees_id);
      }
    }
  }, [location]);

  useEffect(() => {
    async function fetchAttendeesDetail() {
      setLoading(true);
      try {
        const result = await ApiService.get(`participants/${id}`);

        if (result && result.participant && result.registration && result.template_data) {
          setSummaryInfo(result.registration);
          const participantKeys = Object.keys(result.participant);

          participantKeys.forEach((item: any) => {
            if (typeof result.participant[item] !== 'boolean' && typeof result.participant[item] !== 'number') {
              if (!result.participant[item] || result.participant[item] === "") {
                result.participant[item] = "N/A"
              }
            }
          });

          setGeneralInfo(result.participant);

          const keys = Object.keys(result.template_data);
          const keysOfRegisInfo = Object.keys(result.registration);
          const template = [] as any;

          keys.forEach((item: any) => {
            for (let i = 0; i < result.template_data[item].fields.length; i++) {
              for (let j = 0; j < keysOfRegisInfo.length; j++) {
                if (keysOfRegisInfo[j] === result.template_data[item].fields[i].key) {
                  if (result.template_data[item].fields[i].type !== 'select') {
                    if (result.registration[keysOfRegisInfo[j]] && result.registration[keysOfRegisInfo[j]] !== '') {
                      result.template_data[item].fields[i].value = result.registration[keysOfRegisInfo[j]];
                    } else {
                      if (typeof result.template_data[item].fields[i].value !== 'boolean' && typeof result.participant[item] !== 'number') {
                        result.template_data[item].fields[i].value = "N/A";
                      }
                    }
                  } else {
                    if (result.registration[keysOfRegisInfo[j]] && result.registration[keysOfRegisInfo[j]] !== '') {
                      const select_key = Object.keys(result.template_data[item].fields[i].option);

                      select_key.forEach((key_item: string) => {
                        if (result.registration[keysOfRegisInfo[j]] === key_item) {
                          result.template_data[item].fields[i].value = get(result.template_data[item].fields[i].option[key_item], `label_${language}`, '');
                        }
                      })
                    } else {
                      result.template_data[item].fields[i].value = "N/A";
                    }
                  }
                }
              }
            }

            template.push(result.template_data[item]);
          });

          setTemplateData(template);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }

      setLoading(false);
    }

    if (id !== '') {
      fetchAttendeesDetail();
    }

    // eslint-disable-next-line
  }, [id]);

  const handleNavigateOrderDetail = () => {
    window.location.href = `/orders/${generalInfo.order_code}/detail`;
  }

  return (
    <div className="attendees-detail-form">
      <div className={`detail-form__header justify-between ${mobile ? '!h-[80px]' : ''}`}>
        <span>
          {t("sport-attendees-detail")}
        </span>

        {
          generalInfo?.status === 1 &&
          <a href={`/attendees/sports/${id}/edit`}>
            <div className="h-[48px] px-[12px] flex items-center justify-center border-[1px] rounded-[4px] border-[#011BB6] cursor-pointer">
              <img src="/images/icons/icon-edit-detail.svg" className="mr-[4px]" alt="icon-edit" />

              <div className="text-[14px] font-[500] text-[#011BB6]">{t('edit')}</div>
            </div>
          </a>
        }

      </div>

      <div className="detail-form__body">
        {
          generalInfo &&
          <div className="form-item">
            <div className="form-item__header">
              <div className="form-item__header__label">{t('general-information')}</div>
            </div>

            <div className="form-item__body">
              <div className="form-item__information">
                <div className="form-item__information__label">{t('status')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.status === 1 ?
                      <div className="status active">{t('active')}</div>
                      :
                      <div className="status in-active">{t('in-active')}</div>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('category')}</div>
                <div className="form-item__information__value">{generalInfo.category_name_en}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('event-name')}</div>
                <div className="form-item__information__value">{get(generalInfo, `event_name_${language}`, '')}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('is-tranferred')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.is_transferred === "1" ?
                      <span>{t('yes')}</span>
                      :
                      <span>{t('not')}</span>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('ticket-name')}</div>
                <div className="form-item__information__value">{get(generalInfo, `product_name_${language}`, '')}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('is-change-distance')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.is_change_distance ?
                      <span>{t('yes')}</span>
                      :
                      <span>{t('not')}</span>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('time-to-change-distance')}</div>
                <div className="form-item__information__value">{generalInfo.time_to_change_distance}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('order-code')}</div>
                <div className="form-item__information__value order-code" onClick={handleNavigateOrderDetail}>{generalInfo.order_code}</div>
              </div>
            </div>
          </div>
        }

        {
          (templateData && sunmaryInfo) &&
          <div className="form-item">
            <div className="form-item__header">
              <div className="form-item__header__label">{t('registration-information')}</div>
            </div>

            <div className="form-item__body">
              {
                templateData.map((group: any) => {
                  return (
                    <div className="form-item__group" key={group.group_key}>
                      <div className="group-name">{get(group, `label_${language}`, '')}</div>

                      <div className="fields">
                        {
                          group.fields.map((item: any) => {
                            return (
                              <div className="form-item__information" key={item.key}>
                                <div className="form-item__information__label">{get(item, `label_${language}`, '')}</div>
                                {
                                  item.key.indexOf('phone') === -1 ?
                                    <div className="form-item__information__value">{t(item.value)}</div>
                                    :
                                    <div className="form-item__information__value">+{sunmaryInfo[`${item.key}_area_code`]} {item.value}</div>
                                }
                              </div>
                            )
                          })
                        }
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </div>
        }

      </div>

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const SportAttendeesDetail = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <SportAttendeesDetailUI
        t={t}
        AttendeesDetailForm={AttendeesDetailForm}
      />
    )
  } else {
    return (
      <SportAttendeesDetailUIMobile
        t={t}
        AttendeesDetailForm={AttendeesDetailForm}
      />
    )
  }
}

export default SportAttendeesDetail;