import { useState, useEffect } from 'react';
import useWindowWidth from "@helpers/InitWidthDevice";
import CreateAccountUI from "UI/desktop/Organization/AccountManagement/Create/CreateAccount";
import CreateAccountUIMobile from "UI/mobile/Organization/AccountManagement/Create/CreateAccount";
import { Formik, Field, Form } from 'formik';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { Button, Tooltip, Switch, Select, Option } from "@material-tailwind/react";
import Account from '@helpers/Interface';
import { COUNTRY } from '@helpers/constants';
import { useTranslation } from "react-i18next";
import MultiSelect from 'react-select';
import ApiService from '@services/ApiService';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

const FormCreateAccount = (props: any) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const [role, setRole] = useState('');
  const [event, setEvent] = useState([]);
  const { t } = props;
  const { i18n: { language } = {} } = useTranslation();
  const [roleList, setRoleList] = useState([] as any);

  const [events, setEvents] = useState([] as any);
  const [error, setError] = useState({
    role: '',
    event: ''
  } as any);
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    const intervalFetchData = setInterval(() => {
      setFetchData(true);
      clearInterval(intervalFetchData);
    }, 100);
  }, []);

  useEffect(() => {
    async function fetchEventList() {
      setLoading(true);
      try {
        const result = await ApiService.get(`events`);

        if (result) {
          const arr = [] as any;

          result.forEach((item: any) => {
            const obj = {
              value: item.id,
              label: get(item, `name_${language}`, '')
            };

            arr.push(obj);
          });

          setEvents(arr);
          setLoading(false);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    }

    if (fetchData) {
      fetchEventList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [language, fetchData]);

  const [phoneCode, setPhoneCode] = useState('84');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchListRole() {
      setLoading(true);
      try {
        const result = await ApiService.get(`role/all/ids`);

        if (result) {
          const roles = [] as any;

          result.forEach((item: any) => {
            if (item.name.toLowerCase() !== "owner") {
              const obj = {
                value: item.id,
                label: item.name
              };

              roles.push(obj);
            }
          });

          if (roles.length > 0) {
            setRoleList([...roles]);
          }
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
      setLoading(false);
    }

    if (roleList.length === 0 && fetchData) {
      fetchListRole();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, roleList]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);

    const eventSelected: Array<string> = [];

    event.forEach((item: any) => {
      eventSelected.push(item.value);
    })

    const submitData = {
      "email": values.email,
      "first_name": values.first_name,
      "last_name": values.last_name,
      "role_id": role,
      "phone_area_code": phoneCode,
      "phone": values.phone,
      "event_ids": eventSelected,
      "is_active": status
    }

    const err = { ...error } as any;

    if (role === '') {
      err['role'] = t('error-field-require');
    } else {
      err['role'] = '';
    }

    if (eventSelected.length === 0) {
      err['event'] = t('error-field-require');
    } else {
      err['event'] = '';
    }

    setError({ ...err });

    if (err.role === '' && err.event === '') {
      try {
        const result = await ApiService.post(`account`, submitData);

        if (result) {
          setMessage(t("create-successfully"));
          setMessageType("success");

          const redirect = setInterval(() => {
            navigate("/organization/account");
            clearInterval(redirect);
          }, 2000);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
    }

    setSubmitting(false);
    setLoading(false);
  }

  const validateEmail = (value: string) => {
    let error;

    if (!value) {
      error = t('required-email');
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = t('wrong-format-email');
      }
    }

    return error;
  }

  const validateText = (value: string) => {
    let error;

    if (!value) {
      error = t("error-field-require");
    } else {
      let str = value;
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      const regex = /[^a-zA-Z0-9 ]+/;

      if (regex.test(str)) {
        error = t("error-special-character");
      }
    }

    return error;
  }

  return (
    <div className="form-create-account">
      <Formik
        initialValues={{ email: '', first_name: '', last_name: '', phone: '' } as Account}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
      >
        {({
          handleSubmit,
          isSubmitting
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form">
              <div className="form-header">{t('create-new-account')}</div>
              <div className="form-body">
                <div className="form-row">
                  <div className="form-item">
                    <div className="form-label-group">
                      <span className="form-label">{t('status')}</span>

                      <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                    </div>

                    <Switch
                      checked={status}
                      onChange={() => setStatus(!status)}
                      crossOrigin="switch"
                      ripple={false}
                      className="h-full w-full checked:bg-[#0162D1] switch"
                      containerProps={{
                        className: "w-11 h-6",
                      }}
                      circleProps={{
                        className: "before:hidden left-0.5 border-none",
                      }}
                      onPointerEnterCapture="none" onPointerLeaveCapture="none" />
                  </div>

                  <div className="form-item">
                    <div className="form-label-group">
                      <span className="form-label">Email</span>

                      <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />

                      <Tooltip content={t('tooltip-email-create-account')} className="w-[200px]">
                        <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                      </Tooltip>
                    </div>

                    <Field name="email" validate={validateEmail}>
                      {({
                        field,
                        meta,
                      }: any) => (
                        <div className="field-group">
                          <input {...field} type="text" className="input" autoComplete="off" placeholder='Email' />

                          {meta.touched && meta.error && (
                            <div className="field-error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-item">
                    <div className="form-label-group">
                      <span className="form-label">{t('first-name')}</span>

                      <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                    </div>

                    <Field name="first_name" validate={validateText}>
                      {({
                        field,
                        meta,
                      }: any) => (
                        <div className="field-group">
                          <input {...field} type="text" className="input" autoComplete="off" placeholder={t('first-name')} maxLength={50} />

                          <div className="text-length">{field.value.length}/50</div>

                          {meta.touched && meta.error && (
                            <div className="field-error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>

                  </div>

                  <div className="form-item">
                    <div className="form-label-group">
                      <span className="form-label">{t('last-name')}</span>

                      <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                    </div>

                    <Field name="last_name" validate={validateText}>
                      {({
                        field,
                        meta,
                      }: any) => (
                        <div className="field-group">
                          <input {...field} type="text" className="input" autoComplete="off" placeholder={t('last-name')} maxLength={50} />

                          <div className="text-length">{field.value.length}/50</div>

                          {meta.touched && meta.error && (
                            <div className="field-error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-item">
                    <div className="form-label-group">
                      <span className="form-label">{t('role-name')}</span>

                      <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                    </div>

                    <div className="select-role">
                      <MultiSelect
                        defaultValue=""
                        name="categories"
                        options={roleList}
                        className="select-role-name"
                        classNamePrefix="select"
                        placeholder={t('select-role-name')}
                        noOptionsMessage={() => {
                          return t('no-option-role-name')
                        }}
                        onChange={(val: any) => setRole(val.value)} />

                      {error.role !== '' && (
                        <div className="field-error">{error.role}</div>
                      )}
                    </div>

                  </div>

                  <div className="form-item">
                    <div className="form-label-group">
                      <span className="form-label">{t('contact-phone')}</span>
                    </div>

                    <div className="phone-group">
                      <div className="select-phone-code">
                        <Select
                          value={phoneCode}
                          onChange={(val: any) => setPhoneCode(val)}
                          placeholder=""
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {
                            COUNTRY.map((item: any, index: number) => {
                              return (
                                <Option key={`${item.value}_${index}`}
                                  value={item.code}>{item.value} | +{item.code}</Option>
                              )
                            })
                          }
                        </Select>
                      </div>

                      <Field name="phone" >
                        {({
                          field,
                          meta,
                        }: any) => (
                          <div className="input-phone-group">
                            <input {...field} type="text" className="input-phone" autoComplete="off" placeholder={t('phone-number')} />
                          </div>
                        )}
                      </Field>
                    </div>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-item-full">
                    <div className="form-label-group">
                      <span className="form-label">{t('event')}</span>

                      <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                    </div>

                    <MultiSelect
                      defaultValue=""
                      isMulti
                      name="categories"
                      options={events}
                      className="multi-select"
                      classNamePrefix="select"
                      placeholder={t('select-event')}
                      onChange={(e: any) => {
                        const err = { ...error } as any;
                        if (e.length === 0) {
                          setEvent(e);
                          err['event'] = t('error-field-require')
                        } else {
                          setEvent(e);
                          err['event'] = '';
                        }

                        setError({ ...err });
                      }} />

                    {error.event !== '' && (
                      <div className="field-error">{error.event}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="btn-group">
              <Button
                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                placeholder="Button Cancel" className="btn-cancel" onClick={() => window.history.back()}>{t('btn-cancel')}</Button>
              <Button
                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                type="submit" disabled={isSubmitting} placeholder="Button Save" className="btn-save">{t('btn-save')}</Button>
            </div>

            {
              message !== '' &&
              <Message
                message={message}
                messageType={messageType}
                setMessage={setMessage}
              />
            }

            {
              loading &&
              <Loader />
            }
          </Form>
        )}
      </Formik>
    </div>
  )
}

const CreateAccountManagement = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <CreateAccountUI
        FormCreateAccount={FormCreateAccount}
        t={t}
      />
    )
  } else {
    return (
      <CreateAccountUIMobile
        FormCreateAccount={FormCreateAccount}
        t={t}
      />
    )
  }
};

export default CreateAccountManagement;