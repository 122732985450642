import Mobile from '@layouts/mobile';
import './RoleDetail.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const RoleDetailMobileUI = (props: any) => {
  const { t, RoleInformation } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('role-management'),
      url: '/organization/role'
    },
    {
      title: t('role-information'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="role-detail-mobile">
        <RoleInformation
          t={t}
          isMobile={true}
        />
      </div>
    </Mobile>
  )
};

export default RoleDetailMobileUI;