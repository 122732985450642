import Mobile from '@layouts/mobile';
import './AccountDetail.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const AccountDetailUIMobile = (props: any) => {
  const { t, AccountDetailInfo } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('account-management'),
      url: '/organization/account'
    },
    {
      title: t('account-information'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="account-detail-mobile">
        <AccountDetailInfo
          t={t}
          isMobile={true}
        />
      </div>
    </Mobile>
  )
};

export default AccountDetailUIMobile;