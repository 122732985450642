import axios, { AxiosResponse } from 'axios';
import config from "config/config.json";

const BASE_URL = config.BASE_URL + `/api`;

let headers = {} as any;
const usr = localStorage.getItem('usr');

if (usr) {
  const user = JSON.parse(usr);

  headers = {
    'access-token': user.token
  };
}

export default class ApiService {

  static get = async (endpoint: string) => {    
    try {
      const lang = localStorage.getItem('lang');
      headers['accept-language'] = (lang && lang !== '') ? lang : 'vi';

      const response: AxiosResponse = await axios.get(`${BASE_URL}/${endpoint}`, { headers });
      return response.data.data;
    } catch (error: any) {
      if (error.response.data.status_code === 401) {
        const intervalLogout = setInterval(() => {
          forceLogoutUser();
          clearInterval(intervalLogout)
        }, 2000);
      }
      throw error.response.data;
    }
  }

  static post = async (endpoint: string, data: object) => {
    try {
      const lang = localStorage.getItem('lang');
      headers['accept-language'] = (lang && lang !== '') ? lang : 'vi';
      
      const response: AxiosResponse = await axios.post(`${BASE_URL}/${endpoint}`, data, { headers });
      return response.data.data;
    } catch (error: any) {
      if (error.response.data.status_code === 401) {
        const intervalLogout = setInterval(() => {
          forceLogoutUser();
          clearInterval(intervalLogout)
        }, 2000);
      }
      throw error.response.data;
    }
  }

  static put = async (endpoint: string, data: object) => {
    try {
      const lang = localStorage.getItem('lang');
      headers['accept-language'] = (lang && lang !== '') ? lang : 'vi';

      const response: AxiosResponse = await axios.put(`${BASE_URL}/${endpoint}`, data, { headers });
      return response.data.data;
    } catch (error: any) {
      if (error.response.data.status_code === 401) {
        const intervalLogout = setInterval(() => {
          forceLogoutUser();
          clearInterval(intervalLogout)
        }, 2000);
      }
      throw error.response.data;
    }
  }

  static uploadImage = async (postForm: any) => {
    let url = 'https://upload.raceez.vn/image/upload/single';

    const headers_upload_image = {
      'Content-Type': 'multipart/form-data',
    }

    try {
      const response: AxiosResponse = await axios.post(url, postForm, { headers: headers_upload_image });
      return response.data.result;
    } catch (error: any) {
      throw error.response.data;
    }
  }
};

const forceLogoutUser = () => {
  localStorage.removeItem("usr");;
  localStorage.setItem('expired', 'true');
  window.location.href = "/sign-in";
}
