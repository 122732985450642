import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import Message from '@components/common/Message';
import useWindowWidth from '@helpers/InitWidthDevice';
import ForgotPasswordUIDesktop from 'UI/desktop/ForgotPassword';
import ForgotPasswordUIMobile from 'UI/mobile/ForgotPassword';
import UserInfo from '@helpers/Interface';
import Loader from '@components/common/Loader';
import ApiService from '@services/ApiService';

const FormForgotPassword = (props: any) => {
  const { t } = props;
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);

  const validateEmail = (value: any) => {
    let error;

    if (!value) {
      error = t('required-email');
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = t('wrong-format-email');
      }
    }

    return error;
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);
    const href = window.location.href;
    const domain = href.slice(0, href.indexOf('/forgot-password'));

    const submitData = {
      email: values.email,
      domain: (domain.indexOf("localhost") !== -1) ? "https://vietnam-mtb-series.uat-workspace.actiup.net/" : domain
    };
    try {
      const result = await ApiService.post('auth/password/forgot', submitData);

      if (result) {
        setMessage(t('send-email-reset-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ email: '' } as UserInfo}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-item">
            <div className="form-label">Email</div>

            <Field name="email" validate={validateEmail}>
              {({
                field,
                meta,
              }: any) => (
                <div className="field-group">
                  <input {...field} type="text" className="input" placeholder='Email' autoComplete="off" />

                  {meta.touched && meta.error && (
                    <div className="field-error">{meta.error}</div>
                  )}
                </div>
              )}
            </Field>
          </div>

          <button type="submit" disabled={isSubmitting} className="btn-submit">
            {t('btn-submit')}
          </button>

          {
            message !== '' &&
            <Message
              message={message}
              messageType={messageType}
              setMessage={setMessage}
            />
          }

          {
            loading &&
            <Loader />
          }
        </Form>
      )}
    </Formik>
  )
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 1024) {
    return (
      <ForgotPasswordUIDesktop
        FormForgotPassword={FormForgotPassword}
        t={t}
        navigate={navigate}
      />
    )
  } else {
    return (
      <ForgotPasswordUIMobile
        FormForgotPassword={FormForgotPassword}
        t={t}
        navigate={navigate}
      />
    )
  }
}

export default ForgotPassword;