import { useState } from "react";
import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './Dashboard.scss';
import Overview from "@pages/Dashboard/Overview";
import EventTab from "@pages/Dashboard/Event";

const DashboardUI = (props: any) => {
  const { DashboardTab } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const [eventSelected, setEventSelected] = useState('');
  const [eventID, setEventID] = useState('');
  const breadcrumbItems = [
    {
      title: 'Dashboard',
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="dashboard">
        <div className="dashboard-body">
          <DashboardTab
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          {
            currentTab === 0 &&
            <Overview
              setEventSelected={setEventSelected}
              setCurrentTab={setCurrentTab}
              eventID={eventID}
              setEventID={setEventID}
            />
          }

          {
            currentTab === 1 &&
            <EventTab
              eventSelected={eventSelected}
              eventID={eventID}
              setEventID={setEventID}
            />
          }
        </div>
      </div>
    </Desktop>
  )
}

export default DashboardUI;