import Desktop from '@layouts/desktop';
import './EditAccount.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const EditAccountUI = (props: any) => {
  const { FormEditAccount, t } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('account-management'),
      url: '/organization/account'
    },
    {
      title: t('edit-account'),
      url: ''
    },
  ];


  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="edit-account">
        <FormEditAccount
          t={t} />
      </div>
    </Desktop>
  )
}

export default EditAccountUI;