export const COUNTRY = [
  {
      "label": "Afghanistan",
      "value": "AF",
      "code": "93"
  },
  {
      "label": "Aland Islands",
      "value": "AX",
      "code": "358"
  },
  {
      "label": "Albania",
      "value": "AL",
      "code": "355"
  },
  {
      "label": "Algeria",
      "value": "DZ",
      "code": "213"
  },
  {
      "label": "AmericanSamoa",
      "value": "AS",
      "code": "1684"
  },
  {
      "label": "Andorra",
      "value": "AD",
      "code": "376"
  },
  {
      "label": "Angola",
      "value": "AO",
      "code": "244"
  },
  {
      "label": "Anguilla",
      "value": "AI",
      "code": "1264"
  },
  {
      "label": "Antarctica",
      "value": "AQ",
      "code": "672"
  },
  {
      "label": "Antigua and Barbuda",
      "value": "AG",
      "code": "1268"
  },
  {
      "label": "Argentina",
      "value": "AR",
      "code": "54"
  },
  {
      "label": "Armenia",
      "value": "AM",
      "code": "374"
  },
  {
      "label": "Aruba",
      "value": "AW",
      "code": "297"
  },
  {
      "label": "Australia",
      "value": "AU",
      "code": "61"
  },
  {
      "label": "Austria",
      "value": "AT",
      "code": "43"
  },
  {
      "label": "Azerbaijan",
      "value": "AZ",
      "code": "994"
  },
  {
      "label": "Bahamas",
      "value": "BS",
      "code": "1242"
  },
  {
      "label": "Bahrain",
      "value": "BH",
      "code": "973"
  },
  {
      "label": "Bangladesh",
      "value": "BD",
      "code": "880"
  },
  {
      "label": "Barbados",
      "value": "BB",
      "code": "1246"
  },
  {
      "label": "Belarus",
      "value": "BY",
      "code": "375"
  },
  {
      "label": "Belgium",
      "value": "BE",
      "code": "32"
  },
  {
      "label": "Belize",
      "value": "BZ",
      "code": "501"
  },
  {
      "label": "Benin",
      "value": "BJ",
      "code": "229"
  },
  {
      "label": "Bermuda",
      "value": "BM",
      "code": "1441"
  },
  {
      "label": "Bhutan",
      "value": "BT",
      "code": "975"
  },
  {
      "label": "Bolivia, Plurinational State of",
      "value": "BO",
      "code": "591"
  },
  {
      "label": "Bosnia and Herzegovina",
      "value": "BA",
      "code": "387"
  },
  {
      "label": "Botswana",
      "value": "BW",
      "code": "267"
  },
  {
      "label": "Brazil",
      "value": "BR",
      "code": "55"
  },
  {
      "label": "British Indian Ocean Territory",
      "value": "IO",
      "code": "246"
  },
  {
      "label": "Brunei Darussalam",
      "value": "BN",
      "code": "673"
  },
  {
      "label": "Bulgaria",
      "value": "BG",
      "code": "359"
  },
  {
      "label": "Burkina Faso",
      "value": "BF",
      "code": "226"
  },
  {
      "label": "Burundi",
      "value": "BI",
      "code": "257"
  },
  {
      "label": "Cambodia",
      "value": "KH",
      "code": "855"
  },
  {
      "label": "Cameroon",
      "value": "CM",
      "code": "237"
  },
  {
      "label": "Canada",
      "value": "CA",
      "code": "1"
  },
  {
      "label": "Cape Verde",
      "value": "CV",
      "code": "238"
  },
  {
      "label": "Cayman Islands",
      "value": "KY",
      "code": " 345"
  },
  {
      "label": "Central African Republic",
      "value": "CF",
      "code": "236"
  },
  {
      "label": "Chad",
      "value": "TD",
      "code": "235"
  },
  {
      "label": "Chile",
      "value": "CL",
      "code": "56"
  },
  {
      "label": "China",
      "value": "CN",
      "code": "86"
  },
  {
      "label": "Christmas Island",
      "value": "CX",
      "code": "61"
  },
  {
      "label": "Cocos (Keeling) Islands",
      "value": "CC",
      "code": "61"
  },
  {
      "label": "Colombia",
      "value": "CO",
      "code": "57"
  },
  {
      "label": "Comoros",
      "value": "KM",
      "code": "269"
  },
  {
      "label": "Congo",
      "value": "CG",
      "code": "242"
  },
  {
      "label": "Congo, The Democratic Republic of the Congo",
      "value": "CD",
      "code": "243"
  },
  {
      "label": "Cook Islands",
      "value": "CK",
      "code": "682"
  },
  {
      "label": "Costa Rica",
      "value": "CR",
      "code": "506"
  },
  {
      "label": "Cote d'Ivoire",
      "value": "CI",
      "code": "225"
  },
  {
      "label": "Croatia",
      "value": "HR",
      "code": "385"
  },
  {
      "label": "Cuba",
      "value": "CU",
      "code": "53"
  },
  {
      "label": "Cyprus",
      "value": "CY",
      "code": "357"
  },
  {
      "label": "Czech Republic",
      "value": "CZ",
      "code": "420"
  },
  {
      "label": "Denmark",
      "value": "DK",
      "code": "45"
  },
  {
      "label": "Djibouti",
      "value": "DJ",
      "code": "253"
  },
  {
      "label": "Dominica",
      "value": "DM",
      "code": "1767"
  },
  {
      "label": "Dominican Republic",
      "value": "DO",
      "code": "1849"
  },
  {
      "label": "Ecuador",
      "value": "EC",
      "code": "593"
  },
  {
      "label": "Egypt",
      "value": "EG",
      "code": "20"
  },
  {
      "label": "El Salvador",
      "value": "SV",
      "code": "503"
  },
  {
      "label": "Equatorial Guinea",
      "value": "GQ",
      "code": "240"
  },
  {
      "label": "Eritrea",
      "value": "ER",
      "code": "291"
  },
  {
      "label": "Estonia",
      "value": "EE",
      "code": "372"
  },
  {
      "label": "Ethiopia",
      "value": "ET",
      "code": "251"
  },
  {
      "label": "Falkland Islands (Malvinas)",
      "value": "FK",
      "code": "500"
  },
  {
      "label": "Faroe Islands",
      "value": "FO",
      "code": "298"
  },
  {
      "label": "Fiji",
      "value": "FJ",
      "code": "679"
  },
  {
      "label": "Finland",
      "value": "FI",
      "code": "358"
  },
  {
      "label": "France",
      "value": "FR",
      "code": "33"
  },
  {
      "label": "French Guiana",
      "value": "GF",
      "code": "594"
  },
  {
      "label": "French Polynesia",
      "value": "PF",
      "code": "689"
  },
  {
      "label": "Gabon",
      "value": "GA",
      "code": "241"
  },
  {
      "label": "Gambia",
      "value": "GM",
      "code": "220"
  },
  {
      "label": "Georgia",
      "value": "GE",
      "code": "995"
  },
  {
      "label": "Germany",
      "value": "DE",
      "code": "49"
  },
  {
      "label": "Ghana",
      "value": "GH",
      "code": "233"
  },
  {
      "label": "Gibraltar",
      "value": "GI",
      "code": "350"
  },
  {
      "label": "Greece",
      "value": "GR",
      "code": "30"
  },
  {
      "label": "Greenland",
      "value": "GL",
      "code": "299"
  },
  {
      "label": "Grenada",
      "value": "GD",
      "code": "1473"
  },
  {
      "label": "Guadeloupe",
      "value": "GP",
      "code": "590"
  },
  {
      "label": "Guam",
      "value": "GU",
      "code": "1671"
  },
  {
      "label": "Guatemala",
      "value": "GT",
      "code": "502"
  },
  {
      "label": "Guernsey",
      "value": "GG",
      "code": "44"
  },
  {
      "label": "Guinea",
      "value": "GN",
      "code": "224"
  },
  {
      "label": "Guinea-Bissau",
      "value": "GW",
      "code": "245"
  },
  {
      "label": "Guyana",
      "value": "GY",
      "code": "595"
  },
  {
      "label": "Haiti",
      "value": "HT",
      "code": "509"
  },
  {
      "label": "Holy See (Vatican City State)",
      "value": "VA",
      "code": "379"
  },
  {
      "label": "Honduras",
      "value": "HN",
      "code": "504"
  },
  {
      "label": "Hong Kong",
      "value": "HK",
      "code": "852"
  },
  {
      "label": "Hungary",
      "value": "HU",
      "code": "36"
  },
  {
      "label": "Iceland",
      "value": "IS",
      "code": "354"
  },
  {
      "label": "India",
      "value": "IN",
      "code": "91"
  },
  {
      "label": "Indonesia",
      "value": "ID",
      "code": "62"
  },
  {
      "label": "Iran, Islamic Republic of Persian Gulf",
      "value": "IR",
      "code": "98"
  },
  {
      "label": "Iraq",
      "value": "IQ",
      "code": "964"
  },
  {
      "label": "Ireland",
      "value": "IE",
      "code": "353"
  },
  {
      "label": "Isle of Man",
      "value": "IM",
      "code": "44"
  },
  {
      "label": "Israel",
      "value": "IL",
      "code": "972"
  },
  {
      "label": "Italy",
      "value": "IT",
      "code": "39"
  },
  {
      "label": "Jamaica",
      "value": "JM",
      "code": "1876"
  },
  {
      "label": "Japan",
      "value": "JP",
      "code": "81"
  },
  {
      "label": "Jersey",
      "value": "JE",
      "code": "44"
  },
  {
      "label": "Jordan",
      "value": "JO",
      "code": "962"
  },
  {
      "label": "Kazakhstan",
      "value": "KZ",
      "code": "77"
  },
  {
      "label": "Kenya",
      "value": "KE",
      "code": "254"
  },
  {
      "label": "Kiribati",
      "value": "KI",
      "code": "686"
  },
  {
      "label": "Korea, Democratic People's Republic of Korea",
      "value": "KP",
      "code": "850"
  },
  {
      "label": "Korea, Republic of South Korea",
      "value": "KR",
      "code": "82"
  },
  {
      "label": "Kuwait",
      "value": "KW",
      "code": "965"
  },
  {
      "label": "Kyrgyzstan",
      "value": "KG",
      "code": "996"
  },
  {
      "label": "Laos",
      "value": "LA",
      "code": "856"
  },
  {
      "label": "Latvia",
      "value": "LV",
      "code": "371"
  },
  {
      "label": "Lebanon",
      "value": "LB",
      "code": "961"
  },
  {
      "label": "Lesotho",
      "value": "LS",
      "code": "266"
  },
  {
      "label": "Liberia",
      "value": "LR",
      "code": "231"
  },
  {
      "label": "Libyan Arab Jamahiriya",
      "value": "LY",
      "code": "218"
  },
  {
      "label": "Liechtenstein",
      "value": "LI",
      "code": "423"
  },
  {
      "label": "Lithuania",
      "value": "LT",
      "code": "370"
  },
  {
      "label": "Luxembourg",
      "value": "LU",
      "code": "352"
  },
  {
      "label": "Macao",
      "value": "MO",
      "code": "853"
  },
  {
      "label": "Macedonia",
      "value": "MK",
      "code": "389"
  },
  {
      "label": "Madagascar",
      "value": "MG",
      "code": "261"
  },
  {
      "label": "Malawi",
      "value": "MW",
      "code": "265"
  },
  {
      "label": "Malaysia",
      "value": "MY",
      "code": "60"
  },
  {
      "label": "Maldives",
      "value": "MV",
      "code": "960"
  },
  {
      "label": "Mali",
      "value": "ML",
      "code": "223"
  },
  {
      "label": "Malta",
      "value": "MT",
      "code": "356"
  },
  {
      "label": "Marshall Islands",
      "value": "MH",
      "code": "692"
  },
  {
      "label": "Martinique",
      "value": "MQ",
      "code": "596"
  },
  {
      "label": "Mauritania",
      "value": "MR",
      "code": "222"
  },
  {
      "label": "Mauritius",
      "value": "MU",
      "code": "230"
  },
  {
      "label": "Mayotte",
      "value": "YT",
      "code": "262"
  },
  {
      "label": "Mexico",
      "value": "MX",
      "code": "52"
  },
  {
      "label": "Micronesia, Federated States of Micronesia",
      "value": "FM",
      "code": "691"
  },
  {
      "label": "Moldova",
      "value": "MD",
      "code": "373"
  },
  {
      "label": "Monaco",
      "value": "MC",
      "code": "377"
  },
  {
      "label": "Mongolia",
      "value": "MN",
      "code": "976"
  },
  {
      "label": "Montenegro",
      "value": "ME",
      "code": "382"
  },
  {
      "label": "Montserrat",
      "value": "MS",
      "code": "1664"
  },
  {
      "label": "Morocco",
      "value": "MA",
      "code": "212"
  },
  {
      "label": "Mozambique",
      "value": "MZ",
      "code": "258"
  },
  {
      "label": "Myanmar",
      "value": "MM",
      "code": "95"
  },
  {
      "label": "Namibia",
      "value": "NA",
      "code": "264"
  },
  {
      "label": "Nauru",
      "value": "NR",
      "code": "674"
  },
  {
      "label": "Nepal",
      "value": "NP",
      "code": "977"
  },
  {
      "label": "Netherlands",
      "value": "NL",
      "code": "31"
  },
  {
      "label": "Netherlands Antilles",
      "value": "AN",
      "code": "599"
  },
  {
      "label": "New Caledonia",
      "value": "NC",
      "code": "687"
  },
  {
      "label": "New Zealand",
      "value": "NZ",
      "code": "64"
  },
  {
      "label": "Nicaragua",
      "value": "NI",
      "code": "505"
  },
  {
      "label": "Niger",
      "value": "NE",
      "code": "227"
  },
  {
      "label": "Nigeria",
      "value": "NG",
      "code": "234"
  },
  {
      "label": "Niue",
      "value": "NU",
      "code": "683"
  },
  {
      "label": "Norfolk Island",
      "value": "NF",
      "code": "672"
  },
  {
      "label": "Northern Mariana Islands",
      "value": "MP",
      "code": "1670"
  },
  {
      "label": "Norway",
      "value": "NO",
      "code": "47"
  },
  {
      "label": "Oman",
      "value": "OM",
      "code": "968"
  },
  {
      "label": "Pakistan",
      "value": "PK",
      "code": "92"
  },
  {
      "label": "Palau",
      "value": "PW",
      "code": "680"
  },
  {
      "label": "Palestinian Territory, Occupied",
      "value": "PS",
      "code": "970"
  },
  {
      "label": "Panama",
      "value": "PA",
      "code": "507"
  },
  {
      "label": "Papua New Guinea",
      "value": "PG",
      "code": "675"
  },
  {
      "label": "Paraguay",
      "value": "PY",
      "code": "595"
  },
  {
      "label": "Peru",
      "value": "PE",
      "code": "51"
  },
  {
      "label": "Philippines",
      "value": "PH",
      "code": "63"
  },
  {
      "label": "Pitcairn",
      "value": "PN",
      "code": "872"
  },
  {
      "label": "Poland",
      "value": "PL",
      "code": "48"
  },
  {
      "label": "Portugal",
      "value": "PT",
      "code": "351"
  },
  {
      "label": "Puerto Rico",
      "value": "PR",
      "code": "1939"
  },
  {
      "label": "Qatar",
      "value": "QA",
      "code": "974"
  },
  {
      "label": "Romania",
      "value": "RO",
      "code": "40"
  },
  {
      "label": "Russia",
      "value": "RU",
      "code": "7"
  },
  {
      "label": "Rwanda",
      "value": "RW",
      "code": "250"
  },
  {
      "label": "Reunion",
      "value": "RE",
      "code": "262"
  },
  {
      "label": "Saint Barthelemy",
      "value": "BL",
      "code": "590"
  },
  {
      "label": "Saint Helena, Ascension and Tristan Da Cunha",
      "value": "SH",
      "code": "290"
  },
  {
      "label": "Saint Kitts and Nevis",
      "value": "KN",
      "code": "1869"
  },
  {
      "label": "Saint Lucia",
      "value": "LC",
      "code": "1758"
  },
  {
      "label": "Saint Martin",
      "value": "MF",
      "code": "590"
  },
  {
      "label": "Saint Pierre and Miquelon",
      "value": "PM",
      "code": "508"
  },
  {
      "label": "Saint Vincent and the Grenadines",
      "value": "VC",
      "code": "1784"
  },
  {
      "label": "Samoa",
      "value": "WS",
      "code": "685"
  },
  {
      "label": "San Marino",
      "value": "SM",
      "code": "378"
  },
  {
      "label": "Sao Tome and Principe",
      "value": "ST",
      "code": "239"
  },
  {
      "label": "Saudi Arabia",
      "value": "SA",
      "code": "966"
  },
  {
      "label": "Senegal",
      "value": "SN",
      "code": "221"
  },
  {
      "label": "Serbia",
      "value": "RS",
      "code": "381"
  },
  {
      "label": "Seychelles",
      "value": "SC",
      "code": "248"
  },
  {
      "label": "Sierra Leone",
      "value": "SL",
      "code": "232"
  },
  {
      "label": "Singapore",
      "value": "SG",
      "code": "65"
  },
  {
      "label": "Slovakia",
      "value": "SK",
      "code": "421"
  },
  {
      "label": "Slovenia",
      "value": "SI",
      "code": "386"
  },
  {
      "label": "Solomon Islands",
      "value": "SB",
      "code": "677"
  },
  {
      "label": "Somalia",
      "value": "SO",
      "code": "252"
  },
  {
      "label": "South Africa",
      "value": "ZA",
      "code": "27"
  },
  {
      "label": "South Sudan",
      "value": "SS",
      "code": "211"
  },
  {
      "label": "South Georgia and the South Sandwich Islands",
      "value": "GS",
      "code": "500"
  },
  {
      "label": "Spain",
      "value": "ES",
      "code": "34"
  },
  {
      "label": "Sri Lanka",
      "value": "LK",
      "code": "94"
  },
  {
      "label": "Sudan",
      "value": "SD",
      "code": "249"
  },
  {
      "label": "Suriname",
      "value": "SR",
      "code": "597"
  },
  {
      "label": "Svalbard and Jan Mayen",
      "value": "SJ",
      "code": "47"
  },
  {
      "label": "Swaziland",
      "value": "SZ",
      "code": "268"
  },
  {
      "label": "Sweden",
      "value": "SE",
      "code": "46"
  },
  {
      "label": "Switzerland",
      "value": "CH",
      "code": "41"
  },
  {
      "label": "Syrian Arab Republic",
      "value": "SY",
      "code": "963"
  },
  {
      "label": "Taiwan",
      "value": "TW",
      "code": "886"
  },
  {
      "label": "Tajikistan",
      "value": "TJ",
      "code": "992"
  },
  {
      "label": "Tanzania, United Republic of Tanzania",
      "value": "TZ",
      "code": "255"
  },
  {
      "label": "Thailand",
      "value": "TH",
      "code": "66"
  },
  {
      "label": "Timor-Leste",
      "value": "TL",
      "code": "670"
  },
  {
      "label": "Togo",
      "value": "TG",
      "code": "228"
  },
  {
      "label": "Tokelau",
      "value": "TK",
      "code": "690"
  },
  {
      "label": "Tonga",
      "value": "TO",
      "code": "676"
  },
  {
      "label": "Trinidad and Tobago",
      "value": "TT",
      "code": "1868"
  },
  {
      "label": "Tunisia",
      "value": "TN",
      "code": "216"
  },
  {
      "label": "Turkey",
      "value": "TR",
      "code": "90"
  },
  {
      "label": "Turkmenistan",
      "value": "TM",
      "code": "993"
  },
  {
      "label": "Turks and Caicos Islands",
      "value": "TC",
      "code": "1649"
  },
  {
      "label": "Tuvalu",
      "value": "TV",
      "code": "688"
  },
  {
      "label": "Uganda",
      "value": "UG",
      "code": "256"
  },
  {
      "label": "Ukraine",
      "value": "UA",
      "code": "380"
  },
  {
      "label": "United Arab Emirates",
      "value": "AE",
      "code": "971"
  },
  {
      "label": "United Kingdom",
      "value": "GB",
      "code": "44"
  },
  {
      "label": "United States",
      "value": "US",
      "code": "1"
  },
  {
      "label": "Uruguay",
      "value": "UY",
      "code": "598"
  },
  {
      "label": "Uzbekistan",
      "value": "UZ",
      "code": "998"
  },
  {
      "label": "Vanuatu",
      "value": "VU",
      "code": "678"
  },
  {
      "label": "Venezuela, Bolivarian Republic of Venezuela",
      "value": "VE",
      "code": "58"
  },
  {
      "label": "Vietnam",
      "value": "VN",
      "code": "84"
  },
  {
      "label": "Virgin Islands, British",
      "value": "VG",
      "code": "1284"
  },
  {
      "label": "Virgin Islands, U.S.",
      "value": "VI",
      "code": "1340"
  },
  {
      "label": "Wallis and Futuna",
      "value": "WF",
      "code": "681"
  },
  {
      "label": "Yemen",
      "value": "YE",
      "code": "967"
  },
  {
      "label": "Zambia",
      "value": "ZM",
      "code": "260"
  },
  {
      "label": "Zimbabwe",
      "value": "ZW",
      "code": "263"
  }
]

export const DEFAULT_PERMISSIONS =  [
    {
      id: 'dashboard',
      name: 'dashboard',
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: false,
      parent: '',
      icon: '/images/icons/icon-dashboard.svg',
      disable: true
    },
    {
      id: 'orders',
      name: 'orders',
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: false,
      parent: '',
      icon: '/images/icons/orders.svg',
      disable: true
    },
    {
      id: "attendees",
      name: "attendees",
      actions: [],
      child: false,
      parent: '',
      icon: '/images/icons/view-grid-add.svg',
      disable: true
    },
    {
      id: "sports",
      name: "sport-attendees",
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true,
        },
      ],
      child: true,
      parent: 'attendees',
      icon: '',
      disable: true
    },
    {
      id: "attractions",
      name: "attraction-attendees",
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: true,
      parent: 'attendees',
      icon: '',
      disable: true
    },
    {
      id: 'organization',
      name: 'organization',
      actions: [],
      child: false,
      parent: '',
      icon: '/images/icons/icon-organization.svg',
      disable: true
    },
    {
      id: 'info',
      name: 'information',
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: true,
      parent: 'organization',
      icon: '',
      disable: true
    },
    {
      id: 'role',
      name: 'role-management',
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: true,
      parent: 'organization',
      icon: '',
      disable: true
    },
    {
      id: 'account',
      name: 'account-management',
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: true,
      parent: 'organization',
      icon: '',
      disable: true
    },
    {
      id: 'coupon',
      name: 'coupon',
      actions: [
        {
          id: 'all',
          label: 'select-all',
          selected: false,
          disable: true
        },
        {
          id: 'view',
          label: 'view',
          selected: false,
          disable: true
        },
        {
          id: 'edit',
          label: 'edit',
          selected: false,
          disable: true
        },
        {
          id: 'create',
          label: 'create',
          selected: false,
          disable: true
        },
      ],
      child: false,
      parent: '',
      icon: '/images/icons/coupon.svg',
      disable: true
    },
  ];