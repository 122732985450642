import Loader from "@components/common/Loader";
import Message from "@components/common/Message";
import useWindowWidth from "@helpers/InitWidthDevice";
import Select from 'react-select';
import ApiService from "@services/ApiService";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { COUNTRY } from "@helpers/constants";
import AttractionAttendeesEditlUI from "UI/desktop/Attendees/Attraction/Edit";
import AttractionAttendeesEditUIMobile from "UI/mobile/Attendees/Attraction/Edit";
import moment from "moment";

const AttendeesEditForm = (props: any) => {
  const { t, isMobile } = props;
  const location = useLocation();
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [generalInfo, setGeneralInfo] = useState(null as any);
  const [templateData, setTemplateData] = useState(null as any);
  const [sunmaryInfo, setSummaryInfo] = useState(null as any);
  const { i18n: { language } = {} } = useTranslation();
  const [error, setError] = useState({} as any);

  useEffect(() => {
    if (location && location.pathname && location.pathname !== '') {
      const start = location.pathname.indexOf('attractions/');
      const end = location.pathname.indexOf('/edit');

      if (start !== -1 && end !== -1) {
        const attendees_id = location.pathname.slice(start + 12, end);

        setId(attendees_id);
      }
    }
  }, [location]);

  useEffect(() => {
    if (id !== '') {
      fetchAttendeesDetail();
    }

    // eslint-disable-next-line
  }, [id]);

  const fetchAttendeesDetail = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`attraction-participants/${id}`);

      if (result && result.participant && result.registration && result.template_data) {
        setSummaryInfo(result.registration);
        const participantKeys = Object.keys(result.participant);

        participantKeys.forEach((item: any) => {
          if (typeof result.participant[item] !== 'boolean' && typeof result.participant[item] !== 'number') {
            if (!result.participant[item] || result.participant[item] === "") {
              result.participant[item] = "N/A"
            }
          }
        });

        setGeneralInfo(result.participant);

        const keys = Object.keys(result.template_data);
        const keysOfRegisInfo = Object.keys(result.registration);
        const template = [] as any;

        keys.forEach((item: any) => {
          for (let i = 0; i < result.template_data[item].fields.length; i++) {
            for (let j = 0; j < keysOfRegisInfo.length; j++) {
              if (keysOfRegisInfo[j] === result.template_data[item].fields[i].key) {
                if (result.registration[keysOfRegisInfo[j]] && result.registration[keysOfRegisInfo[j]] !== '') {
                  result.template_data[item].fields[i].value = result.registration[keysOfRegisInfo[j]];
                } else {
                  if (typeof result.template_data[item].fields[i].value !== 'boolean' && typeof result.participant[item] !== 'number') {
                    result.template_data[item].fields[i].value = "";
                  }
                }
              }
            }
          }

          template.push(result.template_data[item]);
        });

        setTemplateData(template);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleNavigateOrderDetail = () => {
    window.location.href = `/orders/${generalInfo.order_code}/detail`;
  }

  const handleUpdateAttendant = async () => {
    setLoading(true);
    const errs = { ...error } as any;
    const error_key = Object.keys(errs);
    let check_data = true;

    error_key.forEach((item: any) => {
      if (errs[item] !== '') {
        check_data = false;
      }
    })

    if (check_data) {
      let submitData = {
        participant_data: {}
      } as any;

      templateData.forEach((item: any) => {
        item.fields.forEach((field_item: any) => {
          submitData.participant_data[field_item.key] = field_item.value;

          if (field_item.key.includes('phone')) {
            submitData.participant_data[`${field_item.key}_area_code`] = sunmaryInfo[`${field_item.key}_area_code`]
          }
        });
      });

      try {
        const result = await ApiService.put(`participants/${id}`, submitData);

        if (result && result === "Success") {
          setMessage(t('update-success'));
          setMessageType('success');

          const intervalRedirect = setInterval(() => {
            window.location.href = `/attendees/attractions/${id}/detail`;

            clearInterval(intervalRedirect);
          }, 3000);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
    }

    setLoading(false);
  }

  const handleChangeValue = (value: string, template_key: number, group_key: number, required: boolean, key: string) => {
    const data_temp = [...templateData] as any;
    const errs = { ...error } as any;

    data_temp[template_key].fields[group_key].value = value;

    if (required) {
      errs[key] = (value === '') ? t('error-field-require') : '';
    }

    setTemplateData([...data_temp]);
    setError({ ...errs });

    if (key.includes('email')) {
      validateEmail(value, key)
    }

    if (key.includes('phone')) {
      validatePhone(value, key);
    }
  }

  const handleChangeDateValue = (value: string, template_key: number, group_key: number, required: boolean, key: string, field: any) => {
    const data_temp = [...templateData] as any;
    const errs = { ...error } as any;
    let err = '';

    data_temp[template_key].fields[group_key].value = value;

    if (required) {
      err = (value === '') ? t('error-field-dob') : '';
    }

    if (field.option) {
      let validDate;
      if (field?.option?.by && field?.option?.by !== '') {
        validDate = field?.option?.by;
      } else {
        if (generalInfo.start_date || generalInfo.end_date) {
          validDate = generalInfo.start_date ? generalInfo.start_date : generalInfo.end_date;
        } else {
          validDate = moment();
        }
      }

      const validAge = moment(validDate).diff(value, 'years');
      if (value && field.option) {
        if (!field.option.max && validAge < field.option.min) {
          err = t('valid_age_min', {
            min: field.option.min,
          })
        } else if (
          validAge < field.option.min ||
          validAge > field.option.max
        ) {
          err = t('valid_age', {
            min: field.option.min,
            max: field.option.max,
          })
        }
      }
    }

    errs[key] = err;

    setTemplateData([...data_temp]);
    setError({ ...errs });
  }

  const validateEmail = (value: string, key: string) => {
    let err;

    if (!value) {
      err = t('error-field-require');
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        err = t('wrong-format-email');
      }
    }

    const errs = { ...error } as any;

    errs[key] = err;

    setError({ ...errs });
  }

  const validatePhone = (value: string, key: string) => {
    let err;

    if (!value) {
      err = t('error-field-require');
    } else {
      if (!/^\d{5,15}$/i.test(value)) {
        err = t('wrong-format-phone');
      }
    }

    const errs = { ...error } as any;

    errs[key] = err;

    setError({ ...errs });
  }

  return (
    <div className="attendees-detail-form">
      <div className="detail-form__header">{t("attraction-attendees-edit")}</div>

      <div className="detail-form__body">
        {
          generalInfo &&
          <div className="form-item">
            <div className="form-item__header">
              <div className="form-item__header__label">{t('general-information')}</div>
            </div>

            <div className="form-item__body">
              <div className="form-item__information">
                <div className="form-item__information__label">{t('status')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.status === 1 ?
                      <div className="status active">{t('active')}</div>
                      :
                      <div className="status in-active">{t('in-active')}</div>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('category')}</div>
                <div className="form-item__information__value">{generalInfo.category_name_en}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('event-name')}</div>
                <div className="form-item__information__value">{get(generalInfo, `event_name_${language}`, '')}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('is-tranferred')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.is_transferred === "1" ?
                      <span>{t('yes')}</span>
                      :
                      <span>{t('not')}</span>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('ticket-name')}</div>
                <div className="form-item__information__value">{get(generalInfo, `product_name_${language}`, '')}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('is-change-distance')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.is_change_distance ?
                      <span>{t('yes')}</span>
                      :
                      <span>{t('not')}</span>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('time-to-change-distance')}</div>
                <div className="form-item__information__value">{generalInfo.time_to_change_distance}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('order-code')}</div>
                <div className="form-item__information__value order-code" onClick={handleNavigateOrderDetail}>{generalInfo.order_code}</div>
              </div>
            </div>
          </div>
        }

        {
          (templateData && sunmaryInfo) &&
          <div className="form-item">
            <div className="form-item__header">
              <div className="form-item__header__label">{t('registration-information')}</div>
            </div>

            <div className="form-item__body">
              {
                templateData.map((group: any, template_key: number) => {
                  return (
                    <div className="form-item__group" key={group.group_key}>
                      <div className="group-name">{get(group, `label_${language}`, '')}</div>

                      <div className="fields">
                        {
                          group.fields.map((item: any, group_key: number) => {
                            const options = [] as any;
                            const option_phone_area = [] as any;
                            let defaultValueSelect = null as any;

                            if (item.type === 'select') {
                              const keys = Object.keys(item.option);

                              keys.forEach((key_item: string) => {
                                const obj = {
                                  value: key_item,
                                  label: get(item.option[key_item], `label_${language}`, '')
                                };

                                if (key_item === item.value) {
                                  defaultValueSelect = obj;
                                }

                                options.push(obj);
                              });
                            }

                            if (item.key.includes('phone')) {
                              COUNTRY.forEach((country_item: any) => {
                                const obj = {
                                  value: country_item.code,
                                  label: `${country_item.label} | +${country_item.code}`
                                };

                                if (sunmaryInfo[`${item.key}_area_code`] === country_item.code) {
                                  defaultValueSelect = obj;
                                }

                                option_phone_area.push(obj);
                              });

                              if (!defaultValueSelect) {
                                defaultValueSelect = {
                                  value: '84',
                                  label: 'Vietnam | +84'
                                }
                              }
                            }

                            return (
                              <div className="form-item__information__attendant" key={item.key}>
                                <div className="form-item__information__label flex">{get(item, `label_${language}`, '')}
                                  {
                                    item.require &&
                                    <img src="/images/icons/icon-require.svg" className="ml-[3px]" alt="icon-required" />
                                  }
                                </div>
                                {
                                  item.type === 'select' &&
                                  <Select
                                    defaultValue={defaultValueSelect}
                                    name="events"
                                    options={options}
                                    className="participant_select"
                                    classNamePrefix="select"
                                    onChange={(e: any) => {
                                      handleChangeValue(e.value, template_key, group_key, item.require, item.key)
                                    }} />
                                }

                                {
                                  (item.type === 'text' && !item.key.includes('phone')) &&
                                  <input value={item.value} type="text" className="w-[100%] h-[44px] px-[16px] rounded-[4px] border-[1px] border-[#C5CCD2]"
                                    onChange={(e: any) => handleChangeValue(e.target.value, template_key, group_key, item.require, item.key)} />
                                }

                                {
                                  (item.type === 'text' && item.key.includes('phone') && defaultValueSelect) &&
                                  <div className="flex items-center justify-between">
                                    <Select
                                      defaultValue={defaultValueSelect}
                                      name="phone"
                                      options={option_phone_area}
                                      className="participant_select select-phone-code"
                                      classNamePrefix="select"
                                      onChange={(e: any) => {
                                        const summary_temp = { ...sunmaryInfo } as any;

                                        summary_temp[`${item.key}_area_code`] = e.value;

                                        setSummaryInfo({ ...summary_temp });
                                      }} />

                                    <input value={item.value} type="text" className="ml-[10px] flex-1 h-[44px] px-[16px] rounded-[4px] border-[1px] border-[#C5CCD2]" onChange={(e: any) => handleChangeValue(e.target.value, template_key, group_key, item.require, item.key)} />
                                  </div>
                                }

                                {
                                  item.type === 'date' &&
                                  <input value={item.value} type="date" className="w-[100%] h-[44px] px-[16px] rounded-[4px] border-[1px] border-[#C5CCD2] uppercase" onChange={(e: any) => handleChangeDateValue(e.target.value, template_key, group_key, item.require, item.key, item)} />
                                }

                                {
                                  error[item.key] !== '' &&
                                  <div className="text-[#ED4927] text-[14px] mt-[5px]">{error[item.key]}</div>
                                }

                              </div>
                            )
                          })
                        }
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </div>
        }

      </div>

      <div className={`w-[100%] h-[84px] bg-white bottom-0 flex justify-end items-center ${!isMobile ? 'sticky' : 'fixed'}`}>
          <a href={`/attendees/sports/${id}/detail`}>
            <div className="w-[88px] h-[48px] flex items-center justify-center border-[1px] border-[#E9ECFF] rounded-[4px] text-[#011BB6] mr-[12px] text-[14px] cursor-pointer">{t('btn-cancel')}</div>
          </a>

          <div onClick={handleUpdateAttendant} className="w-[88px] h-[48px] flex items-center justify-center border-[1px] border-[#011BB6] bg-[#011BB6] rounded-[4px] text-white mr-[24px] text-[14px] cursor-pointer">{t('btn-save')}</div>

        </div>

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const AttractionAttendeesEdit = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <AttractionAttendeesEditlUI
        t={t}
        AttendeesEditForm={AttendeesEditForm}
      />
    )
  } else {
    return (
      <AttractionAttendeesEditUIMobile
        t={t}
        AttendeesEditForm={AttendeesEditForm}
      />
    )
  }
}

export default AttractionAttendeesEdit;