import Desktop from '@layouts/desktop';
import './EditInformation.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const EditOrganizationInformationUI = (props: any) => {
  const { FormEditOrganization, t } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('information'),
      url: '/organization/info'
    },
    {
      title: t('edit-information'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <div id="edit-organization-ui">
        <Breadcrumb
          breadcrumbItems={breadcrumbItems}
        />

        <div className="form-group">
          <FormEditOrganization
            t={t}
          />
        </div>
      </div>

    </Desktop>
  )
};

export default EditOrganizationInformationUI;