import Breadcrumb from "@components/common/Breadcrumb";
import './Orders.scss';
import Mobile from "@layouts/mobile";

const OrdersUIMobile = (props: any) => {
  const { t, OrdersForm, language } = props;
  const breadcrumbItems = [
    {
      title: t('orders-title'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="orders-mobile">
        <OrdersForm
          t={t}
          mobile={true}
          language={language}
        />
      </div>
    </Mobile>
  )
}

export default OrdersUIMobile;