import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './DatePickerChart.scss';
import { CalendarIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { enUS, vi } from 'date-fns/locale';
import moment from 'moment';
import { XCircleIcon } from '@heroicons/react/20/solid';

registerLocale('vi', vi);
registerLocale('en', enUS);

const DatePickerChart = (props: any) => {
  const { mobile, setDateType, setDateValue, dateType, dateValue, setFilter } = props;
  const { t, i18n: { language } = {} } = useTranslation();
  const [type, setType] = useState('year');
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as any);
  const [openPopover, setOpenPopover] = useState(false);
  const [currentYear, setCurrentYear] = useState('');
  const [minDate, setMinDate] = useState('' as any);
  const [minMonth, setMinMonth] = useState('' as any);

  useEffect(() => {
    if (currentYear === '') {
      const year = moment().format('YYYY')

      const previousYear = +year - 1;
      const min = new Date(previousYear, 0, 1);
      const min_month = new Date(+year, 0, 1);
      setMinMonth(min_month);

      setMinDate(min);

      setCurrentYear(year);

      if (dateType === 'year') {
        handleDateYear(new Date());
      } else if (dateType === 'month') {
        let positon = (dateValue !== '') ? dateValue.indexOf('-') : '';
        let month = dateValue.slice(0, positon);
        const valueYear = dateValue.slice(positon + 1);
        
        month = `${month}-01-${valueYear}`;
        setType('month');
        handleDateMonth(new Date(month));
      } else if (dateType === 'quarter') {
        let positon = (dateValue !== '') ? dateValue.indexOf('-') : '';
        const valueYear = dateValue.slice(positon + 1);

        const quarter = `01-01-${valueYear}`;
        setType('quarter');
        handleDateQuarter(new Date(quarter));
      } else {
        handleDateYear(new Date());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  useEffect(() => {
    setDefaultLocale(language as string);
  }, [language]);

  const handleChangeType = (dateType: string) => {
    setType(dateType);

    if (dateType === 'month') {
      handleDateMonth(new Date());
    } else if (dateType === 'quarter') {
      handleDateQuarter(new Date());
    } else if (dateType === 'year') {
      handleDateYear(new Date());
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }

  const handleDateMonth = (date: any) => {
    const now = new Date(date);
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    setStartDate(firstDay);
    setEndDate(lastDay);

    const month = moment(date).format('MM-YYYY');

    setDateType('month');
    setDateValue(month);
    setFilter(true);
  }

  const handleDateQuarter = (date: any) => {
    const quarter = Math.floor((date.getMonth() / 3));
    const firstDay = new Date(date.getFullYear(), quarter * 3, 1);
    const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 3, 0);

    setStartDate(firstDay);
    setEndDate(lastDay);

    const year = moment(date).format('YYYY');

    setDateValue(year);
    setDateType('quarter');
    setFilter(true);
  }

  const handleDateYear = (date: any) => {
    const currentYear = new Date(date).getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    setStartDate(firstDay);
    setEndDate(lastDay);

    const year = moment(date).format('YYYY');

    setDateValue(year);
    setDateType('year');
    setFilter(true);
  }

  const handleClearDatePicker = () => {
    setOpenPopover(false);

    setStartDate(null);
    setEndDate(null);

    const year = moment().format('YYYY');
    setDateValue(year);
    setDateType('year');
    setFilter(true);
  }

  return (
    <div id="date-picker-chart">
      <Popover placement="bottom" open={openPopover} handler={() => setOpenPopover(!openPopover)}>
        <PopoverHandler onPointerEnterCapture="none" onPointerLeaveCapture="none">
          <div className="input-date">
            {
              !startDate ?
                <div className="placeholder">
                  <span>{t('year')} ({currentYear})</span>
                </div>
                :
                <div className="date-value">
                  {
                    dateType === 'month' ?
                      <>
                        <span className='mr-[5px]'>{t(dateType)}</span>

                        (<span>{startDate.toLocaleDateString('en-GB')}</span>

                        {
                          endDate &&
                          <div>
                            <span className='mr-1 ml-1'>{'-'}</span>
                            <span>{endDate.toLocaleDateString('en-GB')}</span>
                          </div>
                        })
                      </>
                      :
                      <>
                        <span className='mr-[5px]'>{t(dateType)}</span>

                        <span>({dateValue})</span>
                      </>
                  }
                </div>
            }
          </div>
        </PopoverHandler>

        <PopoverContent placeholder="popover date picker"
          className={mobile ? "popup-date-picker popup-date-picker__mobile" : "popup-date-picker"}
          onPointerEnterCapture="none" onPointerLeaveCapture="none">
          <div className="date-type">
            <div className="type-default">

              <div className="type-item" onClick={() => handleChangeType('month')}>
                <span className={type === 'month' ? 'type-selected' : ''}>{t('month')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

              <div className="type-item" onClick={() => handleChangeType('quarter')}>
                <span className={type === 'quarter' ? 'type-selected' : ''}>{t('quarter')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

              <div className="type-item" onClick={() => handleChangeType('year')}>
                <span className={type === 'year' ? 'type-selected' : ''}>{t('year')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

            </div>
          </div>

          <div className="calendar-chart">

            {
              type === 'month' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateMonth}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                maxDate={new Date()}
                inline
                calendarStartDay={1}
                minDate={minMonth}
              />
            }

            {
              type === 'quarter' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateQuarter}
                dateFormat="yyyy"
                showYearPicker
                maxDate={new Date()}
                inline
                calendarStartDay={1}
                minDate={minDate}
                yearItemNumber={2}
              />
            }

            {
              type === 'year' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateYear}
                showYearPicker
                dateFormat="yyyy"
                maxDate={new Date()}
                inline
                calendarStartDay={1}
                minDate={minDate}
                yearItemNumber={2}
              />
            }

          </div>
        </PopoverContent>
      </Popover>

      {
        !startDate ?
          <CalendarIcon className="w-4 h-4 icon-calendar cursor-pointer" onClick={() => setOpenPopover(true)} />
          :
          <XCircleIcon className="w-4 h-4 icon-calendar cursor-pointer" onClick={handleClearDatePicker} />
      }
    </div>
  )
}

export default DatePickerChart;