import Desktop from "@layouts/desktop";
import "./EditOrder.scss";
import Breadcrumb from "@components/common/Breadcrumb";

const EditOrderUI = (props: any) => {
  const { t, OrderForm } = props;

  const breadcrumbItems = [
    {
      title: t('orders-title'),
      url: '/orders'
    },
    {
      title: t('edit-order'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="edit-order">
        <OrderForm
          t={t}
        />
      </div>
    </Desktop>
  )
}

export default EditOrderUI;