import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './DateRangePicker.scss';
import { CalendarIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { enUS, vi } from 'date-fns/locale';
import moment from 'moment';
import { XCircleIcon } from '@heroicons/react/20/solid';

registerLocale('vi', vi);
registerLocale('en', enUS);

const DateRangePicker = (props: any) => {
  const { mobile, quickSelectDate, setApplyDate, setFilter, setSuffix, setPage, applyDate } = props;
  const { t, i18n: { language } = {} } = useTranslation();
  const [type, setType] = useState('days');
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as any);
  const [openPopover, setOpenPopover] = useState(false);
  const [changeDate, setChangeDate] = useState(true);

  useEffect(() => {
    if (applyDate && changeDate) {
      if (applyDate.start !== '') {
        setStartDate(new Date(applyDate.start));

        if (applyDate.end !== '') {
          if (applyDate.start !== applyDate.end) {
            setEndDate(new Date(applyDate.end));
          }
        }
      } else {
        setStartDate(null);
        setEndDate(null);
      }

      setChangeDate(false);
    }

    // eslint-disable-next-line
  }, [applyDate]);

  useEffect(() => {
    if (!openPopover && setFilter) {
      setFilter(true);
    }
  }, [openPopover, setFilter]);

  useEffect(() => {
    const dates = {} as any;

    if (startDate) {
      dates.start = moment(startDate).format('YYYY-MM-DD');

      if (endDate) {
        dates.end = moment(endDate).format('YYYY-MM-DD');
      } else {
        dates.end = moment(startDate).format('YYYY-MM-DD');
      }
    } else {
      dates.start = '';
      dates.end = '';
    }

    setApplyDate({ ...dates });

    if (setPage) {
      setPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [startDate, endDate, setApplyDate, setPage]);

  useEffect(() => {
    setDefaultLocale(language as string);
  }, [language]);

  const handleChangeType = (dateType: string) => {
    setType(dateType);

    if (dateType === 'week') {
      handleDateWeek(new Date());
    } else if (dateType === 'month') {
      handleDateMonth(new Date());
    } else if (dateType === 'quarter') {
      handleDateQuarter(new Date());
    } else if (dateType === 'year') {
      handleDateYear(new Date());
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }

  const handleDateRanges = (dates: any) => {
    if (dates && dates[0]) {
      if (startDate && endDate) {
        setStartDate(dates[0]);
        setEndDate(null);

        if (setSuffix) {
          setSuffix(t('suffix-previous-days', {
            days: `1`,
          }));
        }

      } else {
        if (dates[1]) {
          setStartDate(dates[0]);
          setEndDate(dates[1]);
          const diff = moment(dates[1]).diff(moment(dates[0]), 'days');

          if (setSuffix) {
            setSuffix(t('suffix-previous-days', {
              days: +diff + 1,
            }));
          }

        } else {
          setStartDate(dates[0]);
          if (setSuffix) {
            setSuffix(t('suffix-previous-days', {
              days: `1`,
            }));
          }
          
        }
      }
    }
  };

  const handleDateWeek = (date: any) => {
    const currentDate = date ? new Date(date) : new Date();
    const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + 1;
    const lastDayOfWeek = firstDayOfWeek + 6;

    let firstDate = new Date(currentDate.setDate(firstDayOfWeek));
    let lastDate = new Date(currentDate.setDate(lastDayOfWeek)) as any;

    setStartDate(firstDate);

    if (firstDate > lastDate) {
      const last = new Date(lastDate);
      last.setMonth(lastDate.getMonth() + 1);
      setEndDate(last)
    } else {
      setEndDate(lastDate);
    }

    if (setSuffix) {
      setSuffix(t('suffix-last-week'));
    }
  }

  const handleDateMonth = (date: any) => {
    const now = new Date(date);
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    setStartDate(firstDay);
    setEndDate(lastDay);

    if (setSuffix) {
      setSuffix(t('suffix-last-month'))
    }
  }

  const handleDateQuarter = (date: any) => {
    const quarter = Math.floor((date.getMonth() / 3));
    const firstDay = new Date(date.getFullYear(), quarter * 3, 1);
    const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 3, 0);

    setStartDate(firstDay);
    setEndDate(lastDay);

    if (setSuffix) {
      setSuffix(t('suffix-last-quarter'))
    }
  }

  const handleDateYear = (date: any) => {
    const currentYear = new Date(date).getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    setStartDate(firstDay);
    setEndDate(lastDay);
    if (setSuffix) {
      setSuffix(t('suffix-last-year'))
    }
  }

  const handleYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    setStartDate(yesterday);
    setEndDate(yesterday);
    setOpenPopover(false);
    setType('days');
    if (setSuffix) {
      setSuffix('yesterday')
    }
  }

  const getPreviousDates = (days: number) => {
    const today = new Date();
    const lastDay = new Date(today);
    lastDay.setDate(lastDay.getDate() - 1);
    const firstDay = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);

    setStartDate(firstDay);
    setEndDate(lastDay);
    setOpenPopover(false);

    if (setSuffix) {
      if (days === 7) {
        setSuffix('l7d');
        setType('days');
      } else if (days === 30) {
        setSuffix('l30d');
        setType('days');
      } else {
        setType('days');
      }
    }
  }

  const handleClearDatePicker = () => {
    setOpenPopover(false);
    const dates = {
      start: '',
      end: ''
    };

    setApplyDate({ ...dates });
    setStartDate(null);
    setEndDate(null);

    if (setFilter) {
      setFilter(true);
    }

    if (setPage) {
      setPage(1);
    }
  }

  return (
    <div id="date-range-custom">
      <Popover placement="bottom" open={openPopover} handler={() => setOpenPopover(!openPopover)}>
        <PopoverHandler onPointerEnterCapture="none" onPointerLeaveCapture="none">
          <div className="input-date">
            {
              !startDate ?
                <div className="placeholder">
                  <div>{t('from-date')}</div>
                  <div className='to-date'>
                    <img src="/images/icons/icon-arrow-calendar.svg" alt='icon-calendar' className='icon-arrow-calendar' />
                    <span>{t('to-date')}</span>
                  </div>
                </div>
                :
                <div className="date-value">
                  <span>{startDate.toLocaleDateString('en-GB')}</span>

                  {
                    endDate &&
                    <div>
                      <span className='mr-1 ml-1'>{'-'}</span>
                      <span>{endDate.toLocaleDateString('en-GB')}</span>
                    </div>
                  }
                </div>
            }
          </div>
        </PopoverHandler>

        <PopoverContent placeholder="popover date picker"
          className={mobile ? "popup-date-picker popup-date-picker__mobile" : "popup-date-picker"}
          onPointerEnterCapture="none" onPointerLeaveCapture="none">
          <div className="date-type">
            {
              (quickSelectDate && !mobile) &&
              <div className="type-default">
                <div className="type-item" onClick={() => {
                  setStartDate(new Date());
                  setEndDate(new Date());
                  setOpenPopover(false);
                  setType('days');
                  if (setSuffix) {
                    setSuffix('today')
                  }
                }}>{t('today')}</div>
                <div className="type-item" onClick={handleYesterday}>{t('yesterday')}</div>

                <div className="type-item" onClick={() => getPreviousDates(7)}>{t('last-7-day')}</div>
                <div className="type-item" onClick={() => getPreviousDates(30)}>{t('last-30-day')}</div>
              </div>
            }

            <div className="type-default">
              <div className="type-item" onClick={() => handleChangeType('days')}>
                <span className={type === 'days' ? 'type-selected' : ''}>{t('days')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

              {
                !mobile &&
                <div className="type-item" onClick={() => handleChangeType('week')}>
                  <span className={type === 'week' ? 'type-selected' : ''}>{t('week')}</span>

                  <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                </div>
              }

              <div className="type-item" onClick={() => handleChangeType('month')}>
                <span className={type === 'month' ? 'type-selected' : ''}>{t('month')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

              <div className="type-item" onClick={() => handleChangeType('quarter')}>
                <span className={type === 'quarter' ? 'type-selected' : ''}>{t('quarter')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

              <div className="type-item" onClick={() => handleChangeType('year')}>
                <span className={type === 'year' ? 'type-selected' : ''}>{t('year')}</span>

                {
                  mobile ?
                    <ChevronDownIcon className="w-4 h-4 icon-chevron" />
                    :
                    <ChevronRightIcon className="w-4 h-4 icon-chevron" />
                }
              </div>

            </div>
          </div>

          <div className="calendar">
            {
              type === 'days' &&
              <DatePicker
                // selected={startDate}
                onChange={handleDateRanges}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                calendarStartDay={1}
                selected={(startDate) ? startDate : new Date()}
              />
            }

            {
              type === 'week' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateWeek}
                showWeekPicker
                inline
                calendarStartDay={1}
              />
            }

            {
              type === 'month' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateMonth}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                inline
                calendarStartDay={1}
              />
            }

            {
              type === 'quarter' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateQuarter}
                dateFormat="yyyy, QQQ"
                showQuarterYearPicker
                inline
                calendarStartDay={1}
              />
            }

            {
              type === 'year' &&
              <DatePicker
                selected={startDate}
                onChange={handleDateYear}
                showYearPicker
                dateFormat="yyyy"
                inline
                calendarStartDay={1}
              />
            }

          </div>
        </PopoverContent>
      </Popover>

      {
        !startDate ?
          <CalendarIcon className="w-4 h-4 icon-calendar cursor-pointer" onClick={() => setOpenPopover(true)} />
          :
          <XCircleIcon className="w-4 h-4 icon-calendar cursor-pointer" onClick={handleClearDatePicker} />
      }
    </div>
  )
}

export default DateRangePicker;