import Loader from "@components/common/Loader";
import Message from "@components/common/Message";
import useWindowWidth from "@helpers/InitWidthDevice";
import ApiService from "@services/ApiService";
import AttractionAttendeesDetailUI from "UI/desktop/Attendees/Attraction/Detail";
import AttractionAttendeesDetailUIMobile from "UI/mobile/Attendees/Attraction/Detail";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";


const AttendeesDetailForm = (props: any) => {
  const { t, mobile } = props;
  const location = useLocation();
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [generalInfo, setGeneralInfo] = useState(null as any);
  const [templateData, setTemplateData] = useState(null as any);
  const { i18n: { language } = {} } = useTranslation();

  useEffect(() => {
    if (location && location.pathname && location.pathname !== '') {
      const start = location.pathname.indexOf('attractions/');
      const end = location.pathname.indexOf('/detail');

      if (start !== -1 && end !== -1) {
        const attendees_id = location.pathname.slice(start + 12, end);

        setId(attendees_id);
      }
    }
  }, [location]);

  useEffect(() => {
    async function fetchAttendeesDetail() {
      setLoading(true);
      try {
        const result = await ApiService.get(`attraction-participants/${id}`);

        if (result && result.participant && result.template_data) {
          const participantKeys = Object.keys(result.participant);

          participantKeys.forEach((item: any) => {
            if (typeof result.participant[item] !== 'boolean' && typeof result.participant[item] !== 'number') {
              if (!result.participant[item] || result.participant[item] === "") {
                result.participant[item] = "N/A"
              }
            }
          });

          setGeneralInfo(result.participant);

          const templateKeys = Object.keys(result.template_data);

          const arr = [] as any;

          templateKeys.forEach((keys: any) => {
            arr.push(result.template_data[keys]);
          });

          setTemplateData([...arr]);

        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }

      setLoading(false);
    }

    if (id !== '') {
      fetchAttendeesDetail();
    }
  }, [id]);

  const handleNavigateOrderDetail = () => {
    window.location.href = `/orders/${generalInfo.parent_ref_id}/detail`;
  }

  return (
    <div className="attendees-detail-form">
      <div className={`detail-form__header justify-between ${mobile ? '!h-[80px]' : ''}`}>
        <span>
          {t("attraction-attendees-detail")}
        </span>

        {
          generalInfo?.status === 1 &&
          <a href={`/attendees/sports/${id}/edit`}>
            <div className="h-[48px] px-[12px] flex items-center justify-center border-[1px] rounded-[4px] border-[#011BB6] cursor-pointer">
              <img src="/images/icons/icon-edit-detail.svg" className="mr-[4px]" alt="icon-edit" />

              <div className="text-[14px] font-[500] text-[#011BB6]">{t('edit')}</div>
            </div>
          </a>
        }

      </div>

      <div className="detail-form__body">
        {
          generalInfo &&
          <div className="form-item">
            <div className="form-item__header">
              <div className="form-item__header__label">{t('general-information')}</div>
            </div>

            <div className="form-item__body">
              <div className="form-item__information">
                <div className="form-item__information__label">{t('status')}</div>
                <div className="form-item__information__value">
                  {
                    generalInfo.is_active ?
                      <div className="status active">{t('active')}</div>
                      :
                      <div className="status in-active">{t('in-active')}</div>
                  }
                </div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('ticket-no')}</div>
                <div className="form-item__information__value">{generalInfo.ticket_no}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('event-name')}</div>
                <div className="form-item__information__value">{get(generalInfo, `event_name_${language}`, '')}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('ticket-name')}</div>
                <div className="form-item__information__value">{get(generalInfo, `product_name_${language}`, '')}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('seat-number')}</div>
                <div className="form-item__information__value">{generalInfo.seat_number}</div>
              </div>

              <div className="form-item__information">
                <div className="form-item__information__label">{t('order-code')}</div>
                <div className="form-item__information__value order-code" onClick={handleNavigateOrderDetail}>{(generalInfo.parent_ref_id && generalInfo.parent_ref_id !== '') ? generalInfo.parent_ref_id : 'N/A'}</div>
              </div>
            </div>
          </div>
        }

        {
          (templateData && generalInfo) &&
          <div className="form-item">
            <div className="form-item__header">
              <div className="form-item__header__label">{t('registration-information')}</div>
            </div>

            <div className="form-item__body">
              {
                templateData.map((group: any) => {
                  return (
                    <div className="form-item__group" key={group.group_key}>

                      <div className="fields">
                        {
                          group.fields.map((item: any) => {
                            return (
                              <div className="form-item__information" key={item.key}>
                                <div className="form-item__information__label">{get(item, `label_${language}`, '')}</div>
                                {
                                  item.key.indexOf('phone') === -1 ?
                                    <div className="form-item__information__value">{t(generalInfo[item.key])}</div>
                                    :
                                    <div className="form-item__information__value">+{generalInfo[`${item.key}_area_code`]} {generalInfo[item.key]}</div>
                                }
                              </div>
                            )
                          })
                        }
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </div>
        }

      </div>

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const AttractionAttendeesDetail = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <AttractionAttendeesDetailUI
        t={t}
        AttendeesDetailForm={AttendeesDetailForm}
      />
    )
  } else {
    return (
      <AttractionAttendeesDetailUIMobile
        t={t}
        AttendeesDetailForm={AttendeesDetailForm}
      />
    )
  }
}

export default AttractionAttendeesDetail;