import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './Sport.scss';

const SportAttendeesUI = (props: any) => {
  const { t, SportAttendeesForm } = props;
  const breadcrumbItems = [
    {
      title: t('attendees'),
      url: ''
    },
    {
      title: t('sport-attendees'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="sport-attendees">
        <SportAttendeesForm
          t={t} />
      </div>
    </Desktop>
  )
}

export default SportAttendeesUI;