import Desktop from '@layouts/desktop';
import './SignInUI.scss';
import Language from '@components/common/Language';

const SignInUIDesktop = (props: any) => {
  const { FormSignIn, t, navigate } = props;

  return (
    <Desktop>
      <div id="sign-in">
        <div className="container">
          <div className="form">
            <div className="form-body">
              <div className="header">
                <img
                  src='/images/icons/logo.png'
                  className="logo"
                  alt="logo-company" />

                <Language />
              </div>

              <div className="label">{t('label-sign-in')}</div>

              <div className="form-group">
                <FormSignIn
                  t={t}
                  navigate={navigate}
                />
              </div>

              <div className="forgot-password" onClick={() => navigate('/forgot-password')}>{t('forgot-password')}</div>
            </div>
          </div>

          <img
            src='/images/sign-in-background.png'
            className="img-background"
            alt="background-sign-in-page" />
        </div>
      </div>
    </Desktop>
  )
}

export default SignInUIDesktop;