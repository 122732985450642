import { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  Typography,
  AccordionBody
} from "@material-tailwind/react";
import {
  ChevronDownIcon
} from "@heroicons/react/24/solid";
import './sidebar.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Sidebar = (props: any) => {
  const { permissions } = props;
  const [open, setOpen] = useState("");
  const location = useLocation();
  const { t } = useTranslation();
  const [currentMenu, setCurrentMenu] = useState('');
  const [menu, setMenu] = useState([] as any);

  useEffect(() => {
    if (permissions && menu.length === 0) {
      const menu_temp = [
        {
          id: "dashboard",
          url: "/dashboard",
          name: "Dashboard",
          icon: "/images/icons/dashboard.svg",
          active: false
        },
        {
          id: "orders",
          url: "/orders",
          name: t('menu-orders'),
          icon: "/images/icons/orders.svg",
          active: false
        },
        {
          id: "attendees",
          url: "",
          name: t('attendees'),
          icon: "/images/icons/view-grid-add.svg",
          subMenu: [
            {
              id: "sports",
              url: "/attendees/sports",
              name: t("sport-attendees"),
              icon: "/images/icons/sport-attendees.svg",
              active: false
            },
            {
              id: "attractions",
              url: "/attendees/attractions",
              name: t("attraction-attendees"),
              icon: "/images/icons/attraction-attendees.svg",
              active: false
            },
          ],
          active: false
        },
        {
          id: "organization",
          url: "",
          name: t('organization'),
          icon: "/images/icons/user-group.svg",
          subMenu: [
            {
              id: "info",
              url: "/organization/info",
              name: t("information"),
              icon: "/images/icons/inbox.svg",
              active: false
            },
            {
              id: "role",
              url: "/organization/role",
              name: t("role-management"),
              icon: "/images/icons/clipboard.svg",
              active: false
            },
            {
              id: "account",
              url: "/organization/account",
              name: t("account-management"),
              icon: "/images/icons/users.svg",
              active: false
            },
          ],
          active: false
        },
        {
          id: "coupon",
          url: "/coupon",
          name: t('coupon'),
          icon: "/images/icons/coupon.svg",
          active: false
        },
      ] as any;

      permissions.forEach((per: any) => {
        for (let i = 0; i < menu_temp.length; i++) {
          if (menu_temp[i].id === per.parrent) {
            menu_temp[i].active = true;
          }

          if (menu_temp[i].subMenu) {
            for (let j = 0; j < menu_temp[i].subMenu.length; j++) {
              if (menu_temp[i].subMenu[j].id === per.id) {
                menu_temp[i].subMenu[j].active = true;
              }
            }
          }
        }
      });

      setMenu([...menu_temp]);
    }
  }, [permissions, menu, t]);

  const handleOpen = (value: string) => {
    setOpen(open === value ? "" : value);
  };

  useEffect(() => {
    if (open === "") {
      menu.forEach((menuItem: any) => {
        if (menuItem.subMenu && menuItem.subMenu.length > 0) {
          menuItem.subMenu.forEach((item: any) => {
            if (location.pathname === item.url) {
              handleOpen(menuItem.id);
              setCurrentMenu(item.url);
            }
          })
        } else {
          if (location.pathname === menuItem.url) {
            setCurrentMenu(menuItem.url);
          }
        }
      })
    }
  });

  const handleRedirectHomePage = () => {
    const user = localStorage.getItem('usr');

    if (user) {
      const usr = JSON.parse(user);
      let pers = false;

      if (usr.permissions && usr.permissions.length > 0) {
        usr.permissions.forEach((item: any) => {
          if (item.app === "dashboard") {
            pers = true;
          }
        });
      }

      if (pers) {
        window.location.href = "/";
      } else {
        window.location.href = "/profile";
      }
    }
  }

  return (
    <div id="sidebar"
      className="h-full" >
      <div className="logo-company">
        <img
          src='/images/icons/logo-colorful.svg'
          className="logo cursor-pointer"
          alt="logo-company"
          onClick={handleRedirectHomePage} />
      </div>

      {
        menu.map((menuItem: any, index: number) => {
          return (
            <List placeholder="List"
              key={`menu_${menuItem.name}_${index}`}
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
              className={menuItem.active ? "min-w-[20vw]" : "hide-menu"}>
              {
                (menuItem.subMenu && menuItem.subMenu.length > 0) ?
                  <Accordion
                    open={open === menuItem.id}
                    icon={
                      <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`mx-auto h-4 w-4 transition-transform ${open === menuItem.id ? "rotate-180" : ""}`}
                      />
                    }
                    placeholder="Accordion"
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  >
                    <ListItem
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      className="p-0" selected={open === menuItem.id} placeholder="ListItem">
                      <AccordionHeader
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        onClick={() => handleOpen(menuItem.id)} className="border-b-0 p-3" placeholder="AccordionHeader">
                        <ListItemPrefix
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          placeholder="ListItemPrefix">
                          <img
                            src={menuItem.icon}
                            className="h-5 w-5"
                            alt="logo-sidebar" />
                        </ListItemPrefix>
                        <Typography
                          onPointerEnterCapture="none" onPointerLeaveCapture="none" className="mr-auto menu-name" placeholder="Typography">
                          {menuItem.name}
                        </Typography>
                      </AccordionHeader>
                    </ListItem>

                    <AccordionBody className="py-1 px-4" placeholder="AccordionBody">
                      <List className="p-0" placeholder="List"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none">
                        {
                          menuItem.subMenu.map((item: any, index: number) => {
                            return (
                              <a key={`list_menu_item_${index}`} href={`${item.url}`} className={item.active ? "child-item" : "hide-menu"}>
                                <ListItem placeholder="ListItem" selected={item.url === currentMenu} onPointerEnterCapture="none" onPointerLeaveCapture="none">
                                  <ListItemPrefix placeholder="ListItemPrefix" onPointerEnterCapture="none" onPointerLeaveCapture="none">
                                    <img
                                      src={item.icon}
                                      className="h-5 w-5"
                                      alt="logo-sidebar" />
                                  </ListItemPrefix>
                                  <div className='menu-name'>
                                    {item.name}
                                  </div>
                                </ListItem>
                              </a>
                            )
                          })
                        }
                      </List>
                    </AccordionBody>
                  </Accordion>
                  :
                  <a href={`${menuItem.url}`}>
                    <ListItem placeholder="ListItem" selected={menuItem.url === currentMenu} onPointerEnterCapture="none" onPointerLeaveCapture="none">
                      <ListItemPrefix placeholder="ListItemPrefix" onPointerEnterCapture="none" onPointerLeaveCapture="none">
                        <img
                          src={menuItem.icon}
                          className="h-5 w-5"
                          alt="logo-sidebar" />
                      </ListItemPrefix>

                      <div className='menu-name'>
                        {menuItem.name}
                      </div>
                    </ListItem>
                  </a>
              }
            </List>
          )
        })
      }

      {/* <a href="https://docs.google.com/presentation/d/1ccv_OeUcSc_1vT8tOnLlgb-jiXWR2gHOpX5mq3jau8o/edit#slide=id.g27226cc1f06_0_30" target='_blank' className='absolute mt-[200px] w-full pl-[10px]' rel="noreferrer">
        <ListItem placeholder="ListItem" onPointerEnterCapture="none" onPointerLeaveCapture="none">
          <ListItemPrefix placeholder="ListItemPrefix" onPointerEnterCapture="none" onPointerLeaveCapture="none">
            <img
              src="/images/icons/icon-user-guide.svg"
              className="h-5 w-5"
              alt="logo-sidebar" />
          </ListItemPrefix>

          <div className='menu-name'>
            {t('user-guide')}
          </div>
        </ListItem>
      </a> */}

    </div>
  );
};

export default Sidebar;