import Mobile from '@layouts/mobile';
import './EditAccount.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const EditAccountUIMobile = (props: any) => {
  const { FormEditAccount, t } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('account-management'),
      url: '/organization/account'
    },
    {
      title: t('edit-account'),
      url: ''
    },
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="create-account-mobile">
        <FormEditAccount
          t={t} />
      </div>
    </Mobile>
  )
}

export default EditAccountUIMobile;