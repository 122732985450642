import { useEffect, useState } from "react";
import useWindowWidth from "@helpers/InitWidthDevice";
import ProfileUI from "UI/desktop/Profile";
import ProfileUIMobile from "UI/mobile/Profile";
import { useTranslation } from "react-i18next";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Button } from '@material-tailwind/react';
import { UserIcon, DocumentIcon, SparklesIcon, PhoneIcon } from '@heroicons/react/24/outline';
import ApiService from '@services/ApiService';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { get } from "lodash";

const FormProfile = (props: any) => {
  const { t, isMobile } = props;
  const [profile, setProfile] = useState(null as any);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('successs');
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(null as any);
  const { i18n: { language } = {} } = useTranslation();
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (!profile) {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [profile]);

  useEffect(() => {
    if (fetchData) {
      if (!profile) {
        fetchProfile();
      } else {
        if (profile.events && profile.events.length > 0) {
          fetchEventList();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, fetchData]);

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`account/profile`);

      if (result) {
        setProfile(result);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
      setFetchData(false);
    }
  }

  const fetchEventList = async () => {
    try {
      const result = await ApiService.get(`events`);

      if (result && result.length > 0) {

        const arr = [] as any;
        result.forEach((event: any) => {
          for (let i = 0; i < profile.events.length; i++) {
            if (profile.events[i] === event.id) {
              arr.push(get(event, `name_${language}`, ''));
            }
          }
        });

        setEvents([...arr]);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
    setFetchData(false);
  }

  return (
    <div className="form-profile">
      {
        profile &&
        <div className="form-info">
          <div className="form-header">
            <div className="header-label">{t('my-profile')}</div>

            <a href="/profile/edit">
              <Button
                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                className="btn-edit" placeholder="button edit role">
                <PencilSquareIcon className="w-4 h-4 icon-edit" />
                {
                  !isMobile &&
                  <span>{t('btn-edit')}</span>
                }
              </Button>
            </a>
          </div>

          <div className="form-body">
            <div className="avatar-group">
              <img src={(profile.avatar && profile.avatar !== '') ? profile.avatar : "https://lh3.googleusercontent.com/a/default-user=s96-c"}
                alt="user-avatar" className="avatar" />
            </div>

            <div className="form-item">
              <div className="form-left">
                <div className="icon-group">
                  <UserIcon className="w-5 h-5 icon" />
                </div>

                <div className="label">{t('full-name')}</div>
              </div>

              <div className="form-right">
                {profile.first_name} {profile.last_name}
              </div>
            </div>

            <div className="form-item">
              <div className="form-left">
                <div className="icon-group">
                  <DocumentIcon className="w-5 h-5 icon" />
                </div>

                <div className="label">{t('role-name')}</div>
              </div>

              <div className="form-right">
                {profile.role}
              </div>
            </div>

            <div className="form-item">
              <div className="form-left">
                <div className="icon-group">
                  <SparklesIcon className="w-5 h-5 icon" />
                </div>

                <div className="label">{t('event')}</div>
              </div>

              {
                events && events.length > 0 &&
                <div className="form-right">
                  {
                    events.map((item: any, index: number) => {
                      return (
                        <div key={`event-name-${index}`}>{item}</div>
                      )
                    })
                  }
                </div>
              }

            </div>

            <div className="form-item">
              <div className="form-left">
                <div className="icon-group">
                  <PhoneIcon className="w-5 h-5 icon" />
                </div>

                <div className="label">{t('contact-phone')}</div>
              </div>

              <div className="form-right">
                +{profile.phone_area_code} {profile.phone}
              </div>
            </div>

            <div className="form-item">
              <div className="form-left">
                <div className="icon-group">
                  <img src="/images/icons/mail.svg" alt="icon-email" />
                </div>

                <div className="label">Email</div>
              </div>

              <div className="form-right">
                {profile.email}
              </div>
            </div>
          </div>
        </div>
      }

      <div className="manage-password">
        <div className="label">{t('manage-password')}</div>

        <a href="/change-password">
          <Button
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="btn-edit" placeholder="button edit role">
            <PencilSquareIcon className="w-4 h-4 icon-edit" />
            {
              !isMobile &&
              <span>{t('change-password')}</span>
            }
          </Button>
        </a>
      </div>

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const Profile = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <ProfileUI
        t={t}
        FormProfile={FormProfile}
      />
    )
  } else {
    return (
      <ProfileUIMobile
        t={t}
        FormProfile={FormProfile}
      />
    )
  }
}

export default Profile;