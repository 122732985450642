import { Breadcrumbs } from '@material-tailwind/react';
import { HomeIcon } from '@heroicons/react/20/solid';
import './breadcrumb.scss';

const Breadcrumb = (props: any) => {
  const { breadcrumbItems } = props;
  return (
    <div id="breadcrumb">
      <Breadcrumbs placeholder="Breadcrumbs" className="bg-white" onPointerEnterCapture="none" onPointerLeaveCapture="none" >
        <a href="/" >
          <HomeIcon className="h-5 w-5 icon-home" aria-hidden="true" />
        </a>
        {
          breadcrumbItems && breadcrumbItems.map((item: any, index: number) => {
            return (
              <a key={`${item.title}_${index}`} href={item.url} className='name-route'>
                {item.title}
              </a>
            )
          })
        }
      </Breadcrumbs>
    </div>
  )
};

export default Breadcrumb;