import { useEffect, useState } from 'react';
import useWindowWidth from '@helpers/InitWidthDevice';
import {
  Button,
  Typography,
  Switch,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Select, Option } from "@material-tailwind/react";
import { EyeIcon, PencilIcon } from '@heroicons/react/20/solid';
import Pagination from '@components/common/Pagination';
import RoleManagementUI from 'UI/desktop/Organization/RoleManagement';
import RoleManagementUIMobile from 'UI/mobile/Organization/RoleManagement';
import { useTranslation } from 'react-i18next';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import ApiService from '@services/ApiService';
import DialogConfirm from '@components/common/DialogConfirm';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '@components/common/DateRangePicker';
import moment from 'moment';

const FilterGroup = (props: any) => {
  const { isMobile, openSearchBox, setOpenSearchBox, t, search, setSearch, setFilter, setApplyDate, setPage, applyDate } = props;
  const status_list = [
    {
      value: '',
      label: t('all-status')
    },
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('in-active')
    },
  ];

  const [status, setStatus] = useState('');

  useEffect(() => {
    if (search.status !== '') {
      status_list.forEach((item: any) => {
        if (item.value.toString() === search.status) {
          setStatus(item.value);
        }
      });
    } else {
      setStatus('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleChangeSearchValue = (value: any, type: string) => {
    const values = { ...search } as any;

    values[type] = value;

    if (type === 'status') {
      setStatus(value);
    }

    setSearch({ ...values });
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setFilter(true);
    }
  };

  if (!isMobile) {
    return (
      <div className="filter-group" onKeyDown={handleKeyDown}>
        <div className="filter-item">
          <div className="filter-label">{t('search')}</div>

          <input className="input-search" placeholder={t("placeholder-search-role")}
            onChange={(e: any) => handleChangeSearchValue(e.target.value, 'q')} value={search.q} />
        </div>

        <div className="filter-item date-picker">
          <div className="filter-label">{t('created-date')}</div>
          <DateRangePicker
            quickSelectDate={true}
            applyDate={applyDate}
            setApplyDate={setApplyDate}
          />
        </div>

        <div className="filter-item">
          <div className="filter-label">{t('status')}</div>

          <div className='select-role'>
            <Select
              value={status}
              onChange={(val) => handleChangeSearchValue(val, 'status')}
              placeholder="Select status"
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
            >
              {
                status_list.map((item: any, index: number) => {
                  return (
                    <Option key={`${item.value}_${index}`}
                      value={item.value}>{item.label}</Option>
                  )
                })
              }
            </Select>
          </div>
        </div>

        <Button
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          placeholder="button filter account" className="btn-search" onClick={() => {
            setFilter(true);
            setPage(1);
          }}>
          <MagnifyingGlassIcon className="w-5 h-5 mr-2 icon-search" />

          <span className="btn-title">{t('search')}</span>
        </Button>
      </div>
    )
  } else {
    return (
      <Popover placement="bottom" open={openSearchBox} handler={setOpenSearchBox}>
        <PopoverHandler>
          <div className="btn-search">
            <MagnifyingGlassIcon className="w-7 h-7" />
          </div>
        </PopoverHandler>
        <PopoverContent
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          className="w-96 popup-search" placeholder="PopoverContent">
          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('search')}
          </Typography>

          <input className="input-search"
            onChange={(e: any) => handleChangeSearchValue(e.target.value, 'q')}
            placeholder={t('placeholder-search-role')}
            value={search.q} />

          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('created-date')}
          </Typography>

          <div className="date-picker">
            <DateRangePicker
              mobile={isMobile}
              quickSelectDate={true}
              applyDate={applyDate}
              setApplyDate={setApplyDate}
            />
          </div>

          <Typography
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="box-title" placeholder="Typography">
            {t('status')}
          </Typography>

          <div className='select-role'>
            <Select
              value={status}
              onChange={(val) => handleChangeSearchValue(val, 'status')}
              placeholder={''}
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
            >
              {
                status_list.map((item: any, index: number) => {
                  return (
                    <Option key={`${item.value}_${index}`}
                      value={item.value}>{item.label}</Option>
                  )
                })
              }
            </Select>
          </div>

          <Button
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            placeholder="button filter account" className="btn-search" onClick={() => {
              setFilter(true);
              setOpenSearchBox(false);
              setPage(1);
            }}>
            <MagnifyingGlassIcon className="w-5 h-5 mr-2 icon-search" />

            <span className="btn-title">{t('search')}</span>
          </Button>
        </PopoverContent>
      </Popover>
    )
  }

}

const Dashboard = (props: any) => {
  const { isMobile, t, search, filter, setFilter, applyDate, page, setPage } = props;
  const TABLE_HEAD = [t('no'), t('role-name'), t('created-at'), t('created-by'), t('updated-at'), t('status'), t('action')];

  const [roles, setRoles] = useState([] as any);
  const [paging, setPaging] = useState(null as any);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [activeRole, setActiveRole] = useState({
    is_active: false,
    id: ''
  });
  const [submitAction, setSubmitAction] = useState(false);
  const navigate = useNavigate();
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (filter) {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [filter]);

  useEffect(() => {
    if (fetchData) {
      fetchRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`role/page/${page}?q=${search.q}&gte=${applyDate.start}&lte=${applyDate.end}&is_active=${search.status}&limit=10`);

      if (result) {
        setPaging(result.paging);
        setRoles(result.roles);
        setFilter(false);
        setFetchData(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleConfirmAction = async (is_active: boolean, id: string) => {
    setOpen(true);

    is_active ? setDialogMessage(t('confirm-active-role')) : setDialogMessage(t('confirm-inactive-role'));
    const activeData = {
      id,
      is_active
    };

    setActiveRole(activeData);
  };

  useEffect(() => {
    async function handleActiveRole() {
      try {
        const result = await ApiService.put(`role/${activeRole.id}/status`, activeRole);

        if (result) {
          setSubmitAction(false);
          const role_temp = [...roles];
          role_temp.forEach((item: any) => {
            if (item.id === activeRole.id) {
              item.is_active = activeRole.is_active;
            }
          });
          setRoles([...role_temp]);
          setMessage(t('update-success'));
          setMessageType('success');
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setSubmitAction(false);
      }
    }

    if (submitAction) {
      handleActiveRole();
    }
  }, [submitAction, activeRole, t, roles]);

  if (!isMobile) {
    return (
      <div className="dashboard">
        <div className="h-full w-full overflow-x-auto overflow-y-hidden">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, i) => (
                  <th
                    key={head}
                    className="content-table"
                  >
                    <Typography
                      variant="small"
                      className={(i === 0) ? "leading-none stt" : "leading-none"}
                      placeholder="Typography"
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {roles.map((item: any, index: number) => {
                const isLast = index === roles.length - 1;
                const classes = isLast ? "content-table" : "border-b border-blue-gray-50 content-table";

                return (
                  <tr key={`account_${item.id}_${index}`} className='table-row'>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        placeholder="Typography"
                        className='stt'
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                       {
                          index < 9 &&
                          <span>{paging.current_page - 1}{index + 1}</span>
                        }

                        {
                          index === 9 &&
                          <span>{paging.current_page}0</span>
                        }
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {item.name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {moment(item.created).format('DD/MM/YYYY')}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {item.created_by}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {moment(item.updated).format('DD/MM/YYYY')}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        <Switch
                          checked={(item.is_active) ? true : false}
                          onChange={() => handleConfirmAction(!item.is_active, item.id)}
                          crossOrigin="switch"
                          ripple={false}
                          className={(item.name === 'Owner') ? "h-full w-full checked:bg-[#8AD8FF] switch" : "h-full w-full checked:bg-[#0162D1] switch"}
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                          disabled={item.name === "Owner" && true}
                          onPointerEnterCapture="none" onPointerLeaveCapture="none" />
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="action">
                        <div className="btn-detail" onClick={() => navigate(`/organization/role/${item.id}`)}>
                          <EyeIcon className="w-4 h-4" />
                        </div>

                        {
                          item.name.toLowerCase() !== 'owner' &&
                          <div className="btn-detail" onClick={() => navigate(`/organization/role/${item.id}/edit`)}>
                            <PencilIcon className="w-4 h-4" />
                          </div>
                        }

                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>

          </table>
        </div>

        {
          paging && paging.total_page > 1 &&
          <Pagination
            setPage={setPage}
            paging={paging}
            setFilter={setFilter}
          />
        }


        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        <DialogConfirm
          open={open}
          setOpen={setOpen}
          setSubmitAction={setSubmitAction}
          dialogMessage={dialogMessage}
        />

        {
          loading &&
          <Loader />
        }
      </div>
    )
  } else {
    return (
      <div className="dashboard">
        {
          roles.map((item: any, index: number) => {
            return (
              <div className="account-item" key={`account_${item.email}_${index}`}>
                <div className="account-head">
                  <Switch
                    checked={(item.is_active) ? true : false}
                    onChange={() => handleConfirmAction(!item.is_active, item.id)}
                    crossOrigin="switch"
                    ripple={false}
                    className={(item.name === 'Owner') ? "h-full w-full checked:bg-[#8AD8FF] switch" : "h-full w-full checked:bg-[#0162D1] switch"}
                    containerProps={{
                      className: "w-11 h-6",
                    }}
                    circleProps={{
                      className: "before:hidden left-0.5 border-none",
                    }}
                    onPointerEnterCapture="none" onPointerLeaveCapture="none" />

                  <div className="account-head-right">
                    <div className="btn-detail" onClick={() => navigate(`/organization/role/${item.id}`)}>
                      <EyeIcon className="w-4 h-4" />
                    </div>

                    {
                      item.name.toLowerCase() !== 'owner' &&
                      <div className="btn-detail" onClick={() => navigate(`/organization/role/${item.id}/edit`)}>
                        <PencilIcon className="w-4 h-4" />
                      </div>
                    }

                  </div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('no')}</div>
                  <div className="account-info-value">
                   {
                          index < 9 &&
                          <span>{paging.current_page - 1}{index + 1}</span>
                        }

                        {
                          index === 9 &&
                          <span>{paging.current_page}0</span>
                        }
                  </div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('role-name')}</div>
                  <div className="account-info-value">{item.name}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('created-at')}</div>
                  <div className="account-info-value">{moment(item.created).format('DD/MM/YYYY')}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('created-by')}</div>
                  <div className="account-info-value">{item.created_by}</div>
                </div>

                <div className="account-info-row">
                  <div className="account-info-label">{t('updated-at')}</div>
                  <div className="account-info-value">{moment(item.updated).format('DD/MM/YYYY')}</div>
                </div>
              </div>
            )
          })
        }

        {
          paging && paging.total_page > 1 &&
          <Pagination
            setPage={setPage}
            paging={paging}
            setFilter={setFilter}
          />
        }

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        <DialogConfirm
          open={open}
          setOpen={setOpen}
          setSubmitAction={setSubmitAction}
          dialogMessage={dialogMessage}
        />

        {
          loading &&
          <Loader />
        }
      </div>
    )
  }

}

const AccountManagement = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <RoleManagementUI
        FilterGroup={FilterGroup}
        Dashboard={Dashboard}
        t={t}
      />
    )
  } else {
    return (
      <RoleManagementUIMobile
        FilterGroup={FilterGroup}
        Dashboard={Dashboard}
        t={t}
      />
    )
  }
};

export default AccountManagement;