import Mobile from '@layouts/mobile';
import './Profile.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const ProfileUIMobile = (props: any) => {
  const { t, FormProfile } = props;
  const breadcrumbItems = [
    {
      title: t('my-profile'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="profile-mobile">
        <FormProfile
          t={t}
          isMobile={true}
        />
      </div>
    </Mobile>
  )
};

export default ProfileUIMobile;