import useWindowWidth from "@helpers/InitWidthDevice";
import DashboardUI from "UI/desktop/Dashboard";
import DashboardUIMobile from "UI/mobile/Dashboard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DashboardTab = (props: any) => {
  const { t } = useTranslation();
  const { currentTab, setCurrentTab } = props;
  const navigate = useNavigate();

  return (
    <div className="dashboard-tab">
      <div className={(currentTab === 0) ? "tab-item current-tab" : "tab-item"}
        onClick={() => {
          // navigate(window.location.pathname, { replace: true });
          // window.location.reload();
          window.location.href = '/dashboard';
        }}>{t('overview')}</div>
      <div className={(currentTab === 1) ? "tab-item current-tab" : "tab-item"}
        onClick={() => {
          navigate(window.location.pathname, { replace: true });
          setCurrentTab(1);
        }}>{t('event')}</div>
    </div>
  )
}

const Dashboard = () => {
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <DashboardUI
        DashboardTab={DashboardTab}
      />
    )
  } else {
    return (
      <DashboardUIMobile
        DashboardTab={DashboardTab}
      />
    )
  }
}

export default Dashboard;