import { DocumentTextIcon } from "@heroicons/react/24/outline"
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import ApiService from "@services/ApiService";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import Loader from "../Loader";
import Message from "../Message";
import './Export.scss';

const ExportModule = (props: any) => {
  const { t } = props;
  const location = useLocation();
  const [exportList, setExportList] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [openPopover, setOpenPopover] = useState(false);
  const [readAll, setReadAll] = useState(true);

  useEffect(() => {
    fetchExportList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (openPopover) {
      fetchExportList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopover]);

  const fetchExportList = async () => {
    try {
      const res = await ApiService.get(`exports/popup`);

      if (res) {
        setExportList([...res]);
        let read_all = true;

        if (res?.length > 0) {
          res?.forEach((item: any) => {
            if (item.status === 'success' && !item.is_seen) {
              read_all = false;
            }
          });

          setReadAll(read_all);
        }
      }
    } catch (e: any) {
      console.log('error', e.message);
    }

    setLoading(false);
  }

  const handleDownloadFile = async (id: string, name: string) => {
    setLoading(true);

    try {
      const res = await ApiService.get(`exports/downloads/${id}`);

      if (res && res !== '') {
        const a = document.createElement('a');
        a.href = res;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        fetchExportList();
      }
    } catch (e: any) {
      setMessage(e.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  const handleReadAll = async () => {
    setLoading(true);

    if (exportList.length > 0) {
      const ids = [] as any;

      exportList.forEach((item: any) => {
        ids.push(item.id);
      })

      const submitData = {
        "task_id": ids
      }
      try {
        const res = await ApiService.put(`exports/seen`, submitData);

        if (res) {
          setMessage(t('mark-read-all-successfully'));
          setMessageType('success');
          fetchExportList();
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <div>
      <Popover placement="bottom" open={openPopover} handler={setOpenPopover}>
        <PopoverHandler>
          <div className='w-[40px] h-[40px] rounded-[40px] border-[1px] border-[#A7B4FE] flex items-center justify-center bg-white mr-[10px] cursor-pointer relative'>
            <DocumentTextIcon className='w-5 h-5 text-[#011bb6]' />

            {
              !readAll &&
              <div className='w-[16px] h-[16px] rounded-full bg-[#F1600D] absolute top-[-5px] right-0'></div>
            }

          </div>
        </PopoverHandler>
        <PopoverContent id="popup-export-list" className={(message !== '') ? 'z-[9] w-[588px] p-[0] h-[450px] overflow-auto' : 'w-[588px] z-[100] p-[0] h-[450px] overflow-auto'} placeholder={''} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="h-[64px] px-[24px] border-b-[0.5px] border-[#D9E1E9] flex items-center text-[18px] font-bold text-[#2B3033] justify-between">
            <span>
              {t('most-recent-report')}
            </span>

            <img src="/images/icons/icon-read-all.svg" className="cursor-pointer" alt="icon-read-all" onClick={handleReadAll} />
          </div>

          {
            exportList.length > 0 ?
              <div>
                <div className="h-[64px] bg-[#E9ECFF] flex items-center px-[24px] text-[#0162D1] font-[400] text-[16px]">
                  {t('your-list-report')}
                </div>

                {
                  exportList.map((item: any, index: number) => {
                    return (
                      <div key={`export_item_${index}`} className="h-[88px] px-[24px] flex items-center justify-between border-b-[0.5px] border-[#D9E1E9]">
                        <div className="text-[#2B3033] text-[16px] font-[400]">{item.file_name}</div>

                        {
                          item.status === 'processing' &&
                          <div className="text-[14px] text-[#A8B3BD] font-[400] cursor-default">{t('preparing')}</div>
                        }

                        {
                          item.status === 'error' &&
                          <div className="text-[14px] text-[#B91C1C] font-[400] cursor-default">{t('retry')}</div>
                        }

                        {
                          item.status === 'success' &&
                          <div className="flex items-center justify-center w-[107px] h-[40px] bg-[#011BB6] text-white rounded-[4px] cursor-pointer"
                            onClick={() => handleDownloadFile(item.id, item.file_name)}>
                            <ArrowDownIcon className="w-[16px] h-[16px] mr-[4px]" />

                            <div className="text-[14px] font-[400]">{t('download')}</div>
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
              :
              <div className="h-[328px] flex flex-col items-center justify-center">
                <div className="w-[80px] h-[80px] rounded-full bg-[#F5F6F7] flex items-center justify-center mb-[24px]">
                  <DocumentTextIcon className="w-[32px] h-[32px] text-[##868E95]" />
                </div>

                <div className="text-[#868E95] text-[16px] font-[400]">{t('export-list-empty')}</div>

              </div>
          }

        </PopoverContent>
      </Popover>

      {
        loading &&
        <Loader />
      }

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }
    </div>
  )
}

export default ExportModule