import { useEffect } from 'react';
import './Message.scss';
import useWindowWidth from '@helpers/InitWidthDevice';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const Message = (props: any) => {
  const { message, messageType, setMessage } = props;
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (message !== '') {
      autoHideMessage();
    }
  });

  const autoHideMessage = () => {
    const time = setInterval(() => {
      setMessage('');
      clearInterval(time);
    }, 5000);
  }

  if (messageType && messageType === 'success') {
    return (
      <div id={(windowWidth && windowWidth > 768) ? "message" : "message-mobile"}>
        <div className="success">
          <img
            src='/images/icons/icon-success.svg'
            className="icon-success"
            alt="icon-success" />

          <div className='message-success'>{message}</div>
        </div>
      </div>
    )
  } else {
    return (
      <div id={(windowWidth && windowWidth > 768) ? "message" : "message-mobile"}>
        <div className="error">
          <ExclamationCircleIcon className="h-5 w-5 icon-success" />

          <div className='message-error'>{message}</div>
        </div>
      </div>
    )
  }
}

export default Message;