import Desktop from '@layouts/desktop';
import './AccountDetail.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const AccountDetailUI = (props: any) => {
  const { t, AccountDetailInfo } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('account-management'),
      url: '/organization/account'
    },
    {
      title: t('account-information'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="account-detail">
        <AccountDetailInfo
          t={t} />
      </div>
    </Desktop>
  )
};

export default AccountDetailUI;