import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './AttractionDetaill.scss';

const AttractionAttendeesDetailUI = (props: any) => {
  const { t, AttendeesDetailForm } = props;
  const breadcrumbItems = [
    {
      title: t('attraction-attendees'),
      url: '/attendees/attractions'
    },
    {
      title: t('attraction-attendees-detail'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="attraction-attendees-detail">
        <AttendeesDetailForm
          t={t} />
      </div>
    </Desktop>
  )
}

export default AttractionAttendeesDetailUI;