import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import './DialogConfirm.scss';

const DialogConfirm = (props: any) => {
  const { open, setOpen, dialogMessage, setSubmitAction } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      handler={() => setOpen(!open)}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
      placeholder="dialog confirm action"
      id="dialog-confirm"
      onPointerEnterCapture="none" onPointerLeaveCapture="none"
    >
      <DialogHeader
        placeholder="dialog confirm action header"
        onPointerEnterCapture="none" onPointerLeaveCapture="none">{t('notification')}</DialogHeader>
      <DialogBody
        placeholder="dialog confirm action body"
        onPointerEnterCapture="none" onPointerLeaveCapture="none">
        {dialogMessage}
      </DialogBody>
      <DialogFooter
        placeholder="dialog confirm action footer"
        onPointerEnterCapture="none" onPointerLeaveCapture="none" >
        <Button
          placeholder="Button cancel action"
          className="btn-cancel"
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          onClick={() => setOpen(!open)}>{t('btn-cancel')}</Button>
        <Button
          placeholder="Button confirm action"
          onPointerEnterCapture="none" onPointerLeaveCapture="none"
          className="btn-save" onClick={() => {
            setSubmitAction(true);
            setOpen(!open);
          }}>{t('btn-submit')}</Button>
      </DialogFooter>
    </Dialog>
  )
}

export default DialogConfirm;