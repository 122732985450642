import { useState } from 'react';
import Mobile from '@layouts/mobile';
import './AccountManagement.scss';
import Breadcrumb from '@components/common/Breadcrumb';
import { PlusIcon } from '@heroicons/react/20/solid';

const AccountManagementUIMobile = (props: any) => {
  const { FilterGroup, Dashboard, t } = props;

  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [search, setSearch] = useState({
    q: '',
    status: '',
    role_id: ''
  } as any);
  const [filter, setFilter] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [applyDate, setApplyDate] = useState({
    start: '',
    end: ''
  });
  const [page, setPage] = useState(1);

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('account-management'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />
      <div id="account-management-mobile">
        <div className="header-page">
          <span className="header-title">{t('account-management')}</span>

          <div className="header-right">
            <FilterGroup
              isMobile={true}
              openSearchBox={openSearchBox}
              setOpenSearchBox={setOpenSearchBox}
              filter={filter}
              setFilter={setFilter}
              t={t}
              search={search}
              setSearch={setSearch}
              setMessage={setMessage}
              setMessageType={setMessageType}
              setApplyDate={setApplyDate}
              setPage={setPage}
            />

            <a href="/organization/account/create">
              <div className="btn-create">
                <PlusIcon className="w-7 h-7 icon-create" />
              </div>
            </a>

          </div>

        </div>

        <div className={(openSearchBox) ? "page-body overlay" : "page-body"}>
          <Dashboard
            isMobile={true}
            filter={filter}
            setFilter={setFilter}
            t={t}
            search={search}
            message={message}
            messageType={messageType}
            setMessage={setMessage}
            setMessageType={setMessageType}
            loading={loading}
            setLoading={setLoading}
            applyDate={applyDate}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </Mobile>
  )
}

export default AccountManagementUIMobile;