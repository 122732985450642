import Breadcrumb from "@components/common/Breadcrumb";
import Mobile from "@layouts/mobile";
import './ChangePasswordUIMobile.scss';

const ChangePasswordUIMobile = (props: any) => {
  const { FormChangePassword, t } = props;

  const breadcrumbItems = [
    {
      title: t('my-profile'),
      url: '/profile'
    },
    {
      title: t('change-password'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <div id="change-password-mobile">
        <Breadcrumb
          breadcrumbItems={breadcrumbItems}
        />

        <div className="form-group">
          <FormChangePassword
            t={t}
          />
        </div>

      </div>
    </Mobile>
  )
}

export default ChangePasswordUIMobile;