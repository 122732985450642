import { useEffect, useState } from 'react';
import useWindowWidth from '@helpers/InitWidthDevice';
import AccountDetailUI from 'UI/desktop/Organization/AccountManagement/Detail';
import AccountDetailUIMobile from 'UI/mobile/Organization/AccountManagement/Detail';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-tailwind/react';
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { DocumentIcon, PhoneIcon, SparklesIcon, TagIcon, UserIcon } from '@heroicons/react/24/outline';
import ApiService from '@services/ApiService';
import { useParams } from 'react-router-dom';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { get } from 'lodash';

const AccountDetailInfo = (props: any) => {
  const { t, isMobile } = props;
  const { slug } = useParams();
  const [account, setAccount] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [fetchData, setFetchData] = useState(false);
  const { i18n: { language } = {} } = useTranslation();
  const [events, setEvents] = useState(null as any);

  useEffect(() => {
    if (slug && slug !== '') {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [slug]);

  useEffect(() => {
    if (fetchData) {
      fetchAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchAccountInfo = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`account/${slug}`);

      if (result) {
        setAccount(result);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }

    setFetchData(false);
  }

  useEffect(() => {
    if (account && account.event_ids && account.event_ids.length > 0) {
      fetchEventList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const fetchEventList = async () => {
    try {
      const result = await ApiService.get(`events`);

      if (result && result.length > 0) {
        const arr = [] as any;
        result.forEach((event: any) => {
          for (let i = 0; i < account.event_ids.length; i++) {
            if (account.event_ids[i] === event.id) {
              arr.push(get(event, `name_${language}`, ''));
            }
          }
        });

        setEvents([...arr]);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  return (
    <div className="account-detail-info">
      <div className="form-header">
        <div className="form-label">{t('account-information')}</div>

        <a href={`/organization/account/${slug}/edit`}>
          <Button
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            className="btn-edit" placeholder="button edit role">
            <PencilSquareIcon className="w-4 h-4 icon-edit" />
            {
              !isMobile &&
              <span>{t('btn-edit')}</span>
            }
          </Button>
        </a>
      </div>

      {
        account &&
        <div className="form-body">
          <div className="form-item">
            <div className="form-left">
              <div className="icon-group">
                <TagIcon className="w-5 h-5 stroke-2 icon" />
              </div>

              <div className="form-item-title">{t('status')}</div>
            </div>

            <div className="form-right">{(account.is_active) ? t('active') : t('in-active')}</div>
          </div>

          <div className="form-item">
            <div className="form-left">
              <div className="icon-group">
                <img src="/images/icons/mail.svg" alt="icon-email" />
              </div>

              <div className="form-item-title">Email</div>
            </div>

            <div className="form-right">{account.email}</div>
          </div>

          <div className="form-item">
            <div className="form-left">
              <div className="icon-group">
                <UserIcon className="w-5 h-5 stroke-2 icon" />
              </div>

              <div className="form-item-title">{t('full-name')}</div>
            </div>

            <div className="form-right">{account.first_name} {account.last_name}</div>
          </div>

          <div className="form-item">
            <div className="form-left">
              <div className="icon-group">
                <DocumentIcon className="w-5 h-5 stroke-2 icon" />
              </div>

              <div className="form-item-title">{t('role-name')}</div>
            </div>

            <div className="form-right">{account.role_name}</div>
          </div>

          <div className="form-item">
            <div className="form-left">
              <div className="icon-group">
                <PhoneIcon className="w-5 h-5 stroke-2 icon" />
              </div>

              <div className="form-item-title">{t('contact-phone')}</div>
            </div>

            <div className="form-right">+{account.phone_area_code} {account.phone}</div>
          </div>

          <div className="form-item">
            <div className="form-left">
              <div className="icon-group">
                <SparklesIcon className="w-5 h-5 stroke-2 icon" />
              </div>

              <div className="form-item-title">{t('event')}</div>
            </div>

            {
              events && events.length > 0 &&
              <div className="form-right">
                {
                  events.map((item: any, index: number) => {
                    return (
                      <div key={`${item}_${index}`}>
                        {item}
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>
      }

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }

    </div>
  )
}

const AccountDetail = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <AccountDetailUI
        t={t}
        AccountDetailInfo={AccountDetailInfo}
      />
    )
  } else {
    return (
      <AccountDetailUIMobile
        t={t}
        AccountDetailInfo={AccountDetailInfo}
      />
    )
  }
};

export default AccountDetail;