import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './Attraction.scss';

const AttractionAttendeesUI = (props: any) => {
  const { t, AttractionAttendeesForm } = props;
  const breadcrumbItems = [
    {
      title: t('attendees'),
      url: ''
    },
    {
      title: t('attraction-attendees'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="attraction-attendees">
        <AttractionAttendeesForm
          t={t} />
      </div>
    </Desktop>
  )
}

export default AttractionAttendeesUI;