import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const CustomNextButton = ({ onClick }: any) => (
  <button onClick={onClick} className="custom-next-button size-[24px] rounded-full bg-white absolute top-[15px] right-[10px] z-[5] cursor-pointer flex items-center justify-center">
    <ChevronRightIcon className='size-[16px]' />
  </button>
);

const CustomPrevButton = ({ onClick }: any) => (
  <button onClick={onClick} className="custom-prev-button size-[24px] rounded-full bg-white absolute top-[15px] left-[10px] z-[5] cursor-pointer flex items-center justify-center">
    <ChevronLeftIcon className='size-[16px]' />
  </button>
);

const TopBanner = (props: any) => {
  const { banners, type } = props;
  return (
    <div className='relative'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: '.custom-next-button',
          prevEl: '.custom-prev-button',
        }}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {
          banners.map((item: any, index: number) => {
            return (
              <SwiperSlide key={`top_banner_item_${index}`}>
                <a className="w-[100%] h-[100%] cursor-pointer" href={item.open_url} target="_blank" rel="noreferrer">
                  <img loading="lazy" src={item[type]} className="object-cover w-full h-[54px]" alt="slide-top-banner" />
                </a>
              </SwiperSlide>
            )
          })
        }
      </Swiper>

      <CustomPrevButton />
      <CustomNextButton />
    </div>
  )
};

export default TopBanner;