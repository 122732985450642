import { useState } from 'react';
import useWindowWidth from '@helpers/InitWidthDevice';
import ApiService from '@services/ApiService';
import OrganizationInformationUI from 'UI/desktop/Organization/Information';
import OrganizationInformationUIMobile from 'UI/mobile/Organization/Information';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const OrganizationInformation = () => {
  const windowWidth = useWindowWidth();
  const [organizationInfo, setOrganizationInfo] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`organization`);

      if (result) {
        setOrganizationInfo(result);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  };

  if (windowWidth && windowWidth > 768) {
    return (
      <div>
        <OrganizationInformationUI
          organizationInfo={organizationInfo}
          loading={loading}
          message={message}
          messageType={messageType}
          setMessage={setMessage}
          t={t}
        />
      </div>
    )
  } else {
    return (
      <OrganizationInformationUIMobile
        organizationInfo={organizationInfo}
        loading={loading}
        message={message}
        messageType={messageType}
        setMessage={setMessage}
        t={t} />
    )
  }
};

export default OrganizationInformation;