import Breadcrumb from "@components/common/Breadcrumb";
import './SportDetail.scss';
import Mobile from "@layouts/mobile";

const SportAttendeesDetailUIMobile = (props: any) => {
  const { t, AttendeesDetailForm } = props;
  const breadcrumbItems = [
    {
      title: t('sport-attendees'),
      url: '/attendees/sports'
    },
    {
      title: t('sport-attendees-detail'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="sport-attendees-detail-mobile">
        <AttendeesDetailForm
          t={t}
          mobile={true} />
      </div>
    </Mobile>
  )
}

export default SportAttendeesDetailUIMobile;