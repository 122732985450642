import Mobile from '@layouts/mobile';
import './SignInUI.scss';
import Language from '@components/common/Language';

const SignInUIMobile = (props: any) => {
  const { FormSignIn, t, navigate } = props;

  return (
    <Mobile>
      <div id='sign-in-mobile'>
        <div className='form'>
          <div className='header'>
            <img
              src='/images/icons/logo.png'
              className="logo"
              alt="logo-company" />

            <Language
              mobile={true}
            />
          </div>

          <div className='label'>{t('label-sign-in')}</div>

          <div className="form-group">
            <FormSignIn
              t={t}
              navigate={navigate}
            />
          </div>

          <div className="forgot-password" onClick={() => navigate('/forgot-password')}>{t('forgot-password')}</div>
        </div>
      </div>
    </Mobile>
  )
}

export default SignInUIMobile;