import { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Message from '@components/common/Message';
import useWindowWidth from '@helpers/InitWidthDevice';
import ChangePasswordUI from 'UI/desktop/ChangePassword';
import ChangePasswordUIMobile from 'UI/mobile/ChangePassword';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { Button } from "@material-tailwind/react";
import UserInfo from '@helpers/Interface';
import Loader from '@components/common/Loader';
import ApiService from '@services/ApiService';
import { useNavigate } from 'react-router-dom';

const FormChangePassword = (props: any) => {
  const { t } = props;
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [showPass, setShowPass] = useState({
    password: '',
    new_password: '',
    confirm_password: ''
  } as any);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);
    const href = window.location.href;
    const domain = href.slice(0, href.indexOf('/change-password'));

    const submitData = {
      password: values.password,
      new_password: values.new_password,
      domain: domain
    };

    try {
      const result = await ApiService.put(`auth/password`, submitData);

      if (result) {
        setMessage(t('update-success'));
        setMessageType('success');

        const intervalLogout = setInterval(() => {
          localStorage.clear();
          navigate('/sign-in');

          clearInterval(intervalLogout);
          setLoading(false);
        }, 2000);
        
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
    setSubmitting(false);
  }

  const validatePassword = (value: string) => {
    let error;

    if (!value) {
      error = t('required-password');
    } else {
      if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{6,}/.test(value)) {
        error = t('wrong-format-password');
      }
    }

    return error;
  }

  const handleShowPassword = (value: boolean, type: string) => {
    const showPassValue = { ...showPass };

    showPassValue[type] = value;

    setShowPass({ ...showPassValue });
  };

  return (
    <Formik
      initialValues={{ password: '', new_password: '', confirm_password: '' } as UserInfo}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
      validate={(values) => {
        const errors = {
          confirm_password: ''
        };
        if (values.new_password !== values.confirm_password) {
          errors.confirm_password = t('password-not-match');
          return errors;
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form">
            <div className="form-header">{t('change-password')}</div>

            <div className="form-body">
              <div className="form-item">
                <div className="form-label">{t('current-password')}</div>

                <Field name="password" validate={validatePassword}>
                  {({
                    field,
                    meta,
                  }: any) => (
                    <div className="field-group">
                      <div className="pass-group">
                        <input {...field} type={showPass.password ? "text" : "password"} className="input" placeholder={t('enter-current-password')} autoComplete="off" />

                        {
                          showPass.password ?
                            <EyeIcon className="h-4 w-4 icon-show-pass" onClick={() => handleShowPassword(false, 'password')} />
                            :
                            <EyeSlashIcon className="h-4 w-4 icon-show-pass" onClick={() => handleShowPassword(true, 'password')} />
                        }
                      </div>


                      {meta.touched && meta.error && (
                        <div className="field-error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-item">
                <div className="form-label">{t('new-password')}</div>

                <Field name="new_password" validate={validatePassword}>
                  {({
                    field,
                    meta,
                  }: any) => (
                    <div className="field-group">
                      <div className="pass-group">
                        <input {...field} type={showPass.new_password ? "text" : "password"} className="input" placeholder={t('enter-new-password')} autoComplete="off" />

                        {
                          showPass.new_password ?
                            <EyeIcon className="h-4 w-4 icon-show-pass" onClick={() => handleShowPassword(false, 'new_password')} />
                            :
                            <EyeSlashIcon className="h-4 w-4 icon-show-pass" onClick={() => handleShowPassword(true, 'new_password')} />
                        }
                      </div>


                      {meta.touched && meta.error && (
                        <div className="field-error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-item">
                <div className="form-label">{t('confirm-password')}</div>

                <Field name="confirm_password" validate={validatePassword}>
                  {({
                    field,
                    meta,
                  }: any) => (
                    <div className="field-group">
                      <div className="pass-group">
                        <input {...field} type={showPass.confirm_password ? "text" : "password"} className="input" placeholder={t('enter-confirm-password')} autoComplete="off" />

                        {
                          showPass.confirm_password ?
                            <EyeIcon className="h-4 w-4 icon-show-pass" onClick={() => handleShowPassword(false, 'confirm_password')} />
                            :
                            <EyeSlashIcon className="h-4 w-4 icon-show-pass" onClick={() => handleShowPassword(true, 'confirm_password')} />
                        }
                      </div>


                      {meta.touched && meta.error && (
                        <div className="field-error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <div className="btn-group">
            <Button
              placeholder="Button Cancel"
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
              className="btn-cancel" onClick={() => navigate("/")}>{t('btn-cancel')}</Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              placeholder="Button Save"
              className="btn-save"
              onPointerEnterCapture="none" onPointerLeaveCapture="none">{t('btn-save')}</Button>
          </div>

          {
            message !== '' &&
            <Message
              message={message}
              messageType={messageType}
              setMessage={setMessage}
            />
          }

          {
            loading &&
            <Loader />
          }
        </Form>
      )}
    </Formik>
  )
}

const ChangePassword = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <ChangePasswordUI
        FormChangePassword={FormChangePassword}
        t={t}
      />
    )
  } else {
    return (
      <ChangePasswordUIMobile
        FormChangePassword={FormChangePassword}
        t={t}
      />
    )
  }
}

export default ChangePassword;