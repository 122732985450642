import { useState } from 'react';
import Mobile from '@layouts/mobile';
import './RoleManagement.scss';
import Breadcrumb from '@components/common/Breadcrumb';
import { PlusIcon } from '@heroicons/react/20/solid';

const AccountManagementUIMobile = (props: any) => {
  const { FilterGroup, Dashboard, t } = props;
  const [search, setSearch] = useState({
    q: '',
    status: '',
  } as any);

  const [filter, setFilter] = useState(true);

  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [applyDate, setApplyDate] = useState({
    start: '',
    end: ''
  });
  const [page, setPage] = useState(1);

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('role-management'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />
      <div id="role-management-mobile">
        <div className="header-page">
          <span className="header-title">{t('role-management')}</span>

          <div className="header-right">
            <FilterGroup
              isMobile={true}
              openSearchBox={openSearchBox}
              setOpenSearchBox={setOpenSearchBox}
              t={t}
              search={search}
              setSearch={setSearch}
              setFilter={setFilter}
              setApplyDate={setApplyDate}
              setPage={setPage}
            />

            <a href="/organization/role/create">
              <div className="btn-create">
                <PlusIcon className="w-7 h-7 icon-create" />
              </div>
            </a>

          </div>

        </div>

        <div className={(openSearchBox) ? "page-body overlay" : "page-body"}>
          <Dashboard
            isMobile={true}
            t={t}
            search={search}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            applyDate={applyDate}
            page={page}
            setPage={setPage}
            />
        </div>
      </div>

    </Mobile>
  )
}

export default AccountManagementUIMobile;