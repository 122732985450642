import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './Orders.scss';

const OrdersUI = (props: any) => {
  const { t, OrdersForm, language } = props;
  const breadcrumbItems = [
    {
      title: t('orders-title'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="orders">
        <OrdersForm
          t={t}
          language={language}
        />
      </div>
    </Desktop>
  )
}

export default OrdersUI;