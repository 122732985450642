import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import './Pagination.scss';
import ReactPaginate from "react-paginate";

const Pagination = (props: any) => {
  const { setPage, paging, setFilter } = props;

  const handlePageClick = (e: any) => {
    setPage(e.selected + 1);

    if (setFilter) {
      setFilter(true);
    }
  }

  return (
    <div id="pagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ChevronRightIcon className="icon-next-page" />}
        className="paging"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={paging.total_page}
        activeClassName="active"
        previousLabel={<ChevronLeftIcon className="icon-next-page" />}
        forcePage={paging.current_page - 1}
      />

    </div>
  )
}

export default Pagination;