import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import SignInUIDesktop from 'UI/desktop/SignIn';
import SignInUIMobile from 'UI/mobile/SignIn';
import useWindowWidth from 'helpers/InitWidthDevice';
import ApiService from '@services/ApiService';
import UserInfo from '@helpers/Interface';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'; import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import DialogNotification from '@components/common/DialogNotification';

const FormSignIn = (props: any) => {
  const { t } = props;
  const [showPass, setShowPass] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  useEffect(() => {
    const expired = localStorage.getItem('expired');

    if (expired) {
      setOpen(true);
      setDialogMessage(t('account-expired'));
      localStorage.removeItem('expired');
    }
  }, [t]);

  const validateEmail = (value: string) => {
    let error;

    if (!value) {
      error = t('required-email');
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = t('wrong-format-email');
      }
    }

    return error;
  }

  const validatePassword = (value: string) => {
    let error;

    if (!value) {
      error = t('required-password');
    }

    return error;
  }

  const handleSubmit = async (values: UserInfo, { setSubmitting }: any) => {
    setLoading(true);
    const href = window.location.href;
    const domain = href.slice(0, href.indexOf('/sign-in'));

    const submitData = {
      email: values.email,
      password: values.password,
      domain: (domain.indexOf("localhost") !== -1) ? "https://vietnam-mtb-series.uat-workspace.actiup.net/" : domain
    };
    
    try {
      const result = await ApiService.post('auth/login', submitData);
      let permission = false;
      localStorage.setItem('usr', JSON.stringify(result));

      if (result && result.permissions && result.permissions.length > 0) {
        result.permissions.forEach((item: any) => {
          if (item.app === "dashboard") {
            permission = true;
          }
        });
      }

      if (permission) {
        window.location.href = "/";
      } else {
        window.location.href = "/profile";
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' } as UserInfo}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-item">
            <div className="form-label">Email</div>

            <Field name="email" validate={validateEmail}>
              {({
                field,
                meta,
              }: any) => (
                <div className="field-group">
                  <input {...field} type="text" className="input" placeholder='Email' autoComplete="off" />

                  {meta.touched && meta.error && (
                    <div className="field-error">{meta.error}</div>
                  )}
                </div>
              )}
            </Field>
          </div>

          <div className="form-item">
            <div className="form-label">{t('password')}</div>

            <Field name="password" validate={validatePassword}>
              {({
                field,
                meta,
              }: any) => (
                <div className="field-group">
                  <div className="pass-group">
                    <input {...field} type={showPass ? "text" : "password"} className="input" placeholder={t('password')} autoComplete="off" />

                    {
                      showPass ?
                        <EyeIcon className="h-4 w-4 icon-show-pass-desk" onClick={() => setShowPass(false)} />
                        :
                        <EyeSlashIcon className="h-4 w-4 icon-show-pass-desk" onClick={() => setShowPass(true)} />
                    }
                  </div>


                  {meta.touched && meta.error && (
                    <div className="field-error">{meta.error}</div>
                  )}
                </div>
              )}
            </Field>
          </div>

          <button type="submit" disabled={isSubmitting} className="btn-submit">
            {t('sign-in')}
          </button>

          {
            message !== '' &&
            <Message
              message={message}
              messageType={messageType}
              setMessage={setMessage}
            />
          }

          {
            open &&
            <DialogNotification
              open={open}
              setOpen={setOpen}
              dialogMessage={dialogMessage}
            />
          }

          {
            loading &&
            <Loader />
          }
        </Form>
      )}
    </Formik>
  )
}

const SignIn = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (windowWidth && windowWidth > 1024) {
    return (
      <SignInUIDesktop
        FormSignIn={FormSignIn}
        t={t}
        navigate={navigate}
      />
    )
  } else {
    return (
      <SignInUIMobile
        FormSignIn={FormSignIn}
        t={t}
        navigate={navigate}
      />
    )
  }
}

export default SignIn;