import Desktop from '@layouts/desktop';
import './EditRole.scss';
import Breadcrumb from '@components/common/Breadcrumb';

const EditRoleUI = (props: any) => {
  const { FormEditRole, t } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('role-management'),
      url: '/organization/role'
    },
    {
      title: t('edit-role'),
      url: ''
    },
  ];


  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="edit-role">
        <FormEditRole
          t={t} />
      </div>
    </Desktop>
  )
}

export default EditRoleUI;