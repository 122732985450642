import Desktop from "@layouts/desktop"
import './ChangePasswordUI.scss';
import Breadcrumb from "@components/common/Breadcrumb";

const ChangePasswordUI = (props: any) => {
  const { FormChangePassword, t } = props;

  const breadcrumbItems = [
    {
      title: t('my-profile'),
      url: '/profile'
    },
    {
      title: t('change-password'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <div id="change-password">
        <Breadcrumb
          breadcrumbItems={breadcrumbItems}
        />

        <div className="form-group">
          <FormChangePassword
            t={t}
          />
        </div>

      </div>
    </Desktop>
  )
}

export default ChangePasswordUI;