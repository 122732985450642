import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './Profile.scss';

const ProfileUI = (props: any) => {
  const { t, FormProfile } = props;
  const breadcrumbItems = [
    {
      title: t('my-profile'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="profile">
        <FormProfile
          t={t}
        />
      </div>
    </Desktop>
  )
}

export default ProfileUI;