import Mobile from '@layouts/mobile';
import './ResetPasswordUI.scss';
import Language from '@components/common/Language';
import { useState } from 'react';

const ResetPasswordUIMobile = (props: any) => {
  const { FormResetPassword, t, navigate } = props;
  const [linkValid, setLinkValid] = useState(true);

  if (linkValid) {
    return (
      <Mobile>
        <div id='reset-password-mobile'>
          <div className='form'>
            <div className='header'>
              <img
                src='/images/icons/logo.png'
                className="logo"
                alt="logo-company" />

              <Language
                mobile={true}
              />
            </div>

            <div className='label'>{t('reset-password')}</div>

            <div className="form-group">
              <FormResetPassword
                t={t}
                navigate={navigate}
                setLinkValid={setLinkValid}
              />
            </div>

            <div className="btn-back-group">
              {t('back-to')}
              <div className="btn-back" onClick={() => navigate('/sign-in')}>{t('sign-in')}</div>
            </div>
          </div>
        </div>
      </Mobile>
    )
  } else {
    return (
      <div className='link-invalid-mobile'>
        <img src="/images/icons/logo.png" className='logo-company' alt='logo' />

        <div className="link-invalid-title">{t('link-invalid-title')}</div>
        <div className="link-invalid-content" dangerouslySetInnerHTML={{ __html: t('link-invalid-content') }}></div>

        <a href="/forgot-password">
          <div className="btn-forgor-password">{t('forgot-password')}</div>
        </a>
      </div>
    )
  }

}

export default ResetPasswordUIMobile;