import Breadcrumb from "@components/common/Breadcrumb";
import Desktop from "@layouts/desktop";
import './EditProfile.scss';

const EditProfileUI = (props: any) => {
  const { t, FormEditProfile } = props;
  const breadcrumbItems = [
    {
      title: t('my-profile'),
      url: '/profile'
    },
    {
      title: t('edit-profile'),
      url: ''
    }
  ];

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="edit-profile">
        <FormEditProfile
          t={t}
        />
      </div>
    </Desktop>
  )
}

export default EditProfileUI;