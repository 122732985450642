import { useState, useEffect } from 'react';
import useWindowWidth from '@helpers/InitWidthDevice';
import RoleDetailUI from 'UI/desktop/Organization/RoleManagement/Detail';
import RoleDetailMobileUI from 'UI/mobile/Organization/RoleManagement/Detail';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { TagIcon, DocumentIcon, NewspaperIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { Button, Checkbox } from '@material-tailwind/react';
import ApiService from '@services/ApiService';
import { useParams } from 'react-router-dom';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { DEFAULT_PERMISSIONS } from '@helpers/constants';

const RoleInformation = (props: any) => {
  const { t, isMobile } = props;
  const [roleInfo, setRoleInfo] = useState(null as any);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  let { slug } = useParams();

  const [permission, setPermission] = useState([] as any);

  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (slug && slug !== '') {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [slug]);

  useEffect(() => {
    if (fetchData) {
      fetchListPermission();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchListPermission = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`role/organization/permissions`);

      if (result && result.length > 0) {
        const arr = [...DEFAULT_PERMISSIONS] as any;

        arr.forEach((item: any) => {
          for (let i = 0; i < result.length; i++) {
            const id = (result[i].resource === '') ? result[i].app : result[i].resource;

            if (id === item.id) {
              if (result[i].action.length === 3) {
                item.disable = false;

                item.actions.forEach((action: any) => {
                  action.selected = false;
                  action.disable = false;
                })
              } else {
                if (result[i].action.length > 0) {
                  item.disable = false;

                  for (let j = 0; j < result[i].action.length; j++) {
                    item.actions.forEach((action: any, index: number) => {
                      if (index === 0) {
                        action.disable = false;
                      }

                      if (result[i].action[j] === action.id) {
                        action.selected = false;
                        action.disable = false;
                      }
                    })
                  }
                }
              }

            }

            if (result[i].app === item.id) {
              item.disable = false;
            }
          }
        });

        fetchRoleInfo(arr);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const fetchRoleInfo = async (pers: any) => {
    setLoading(true);
    try {
      const result = await ApiService.get(`role/${slug}`);

      if (result) {
        setRoleInfo(result);
        const permission_temp = [...pers];

        if (result.permissions && result.permissions.length > 0) {
          result.permissions.forEach((item: any) => {
            for (let i = 0; i < permission_temp.length; i++) {
              if (item.resource === '') {
                if (item.app === permission_temp[i].id && item.action && item.action.length > 0) {
                  for (let j = 0; j < permission_temp[i].actions.length; j++) {
                    if (item.action.includes(permission_temp[i].actions[j].id)) {
                      permission_temp[i].actions[j].selected = true;
                    }
                  }
                }
              } else {
                if (item.resource === permission_temp[i].id && item.action && item.action.length > 0) {
                  for (let j = 0; j < permission_temp[i].actions.length; j++) {
                    if (item.action.includes(permission_temp[i].actions[j].id)) {
                      permission_temp[i].actions[j].selected = true;
                    }
                  }
                }
              }
            }
          });
        };

        permission_temp.forEach((item: any) => {
          if (item.actions.length > 0) {
            let action_count = 0;
            let all = 0;

            item.actions.forEach((action: any) => {
              if (!action.disable) {
                action_count = action_count + 1;
              }

              if (action.selected) {
                all = all + 1;
              };
            });

            if (all === action_count - 1) {
              item.actions[0].selected = true;
            }
            
          }
        });

        setPermission([...permission_temp]);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setFetchData(false);
    setLoading(false);
  }

  return (
    <div className="role-information">
      <div className="information-header">
        <div className="header-title">{t('role-information')}</div>

        {
          (roleInfo && roleInfo.name && roleInfo.name.toLowerCase() !== 'owner') &&
          <a href={`/organization/role/${slug}/edit`}>
            <Button
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
              className="btn-edit" placeholder="button edit role">
              <PencilSquareIcon className="w-4 h-4 icon-edit" />
              {
                !isMobile &&
                <span>{t('btn-edit')}</span>
              }
            </Button>
          </a>
        }

      </div>
      {
        roleInfo &&
        <div className="information-body">
          <div className="information-item">
            <div className="item-left">
              <div className="icon-group">
                <TagIcon className="w-4 h-4 icon" />
              </div>

              <span>{t('status')}</span>
            </div>

            <div className="item-right">{(roleInfo.is_active) ? t('active') : t('in-active')}</div>
          </div>

          <div className="information-item">
            <div className="item-left">
              <div className="icon-group">
                <DocumentIcon className="w-4 h-4 icon" />
              </div>

              <span>{t('role-name')}</span>
            </div>

            <div className="item-right">{roleInfo.name}</div>
          </div>

          <div className="information-item">
            <div className="item-left">
              <div className="icon-group">
                <NewspaperIcon className="w-4 h-4 icon" />
              </div>

              <span>{t('role-description')}</span>
            </div>

            <div className="item-right">{roleInfo.description}</div>
          </div>

          <div className="information-item">
            <div className="item-left">
              <div className="icon-group">
                <TableCellsIcon className="w-4 h-4 icon" />
              </div>

              <span>{t('access-permissions')}</span>
            </div>

            <div className="item-right"></div>
          </div>

          <div className="access-permissions">
            {
              permission.map((item: any, index: number) => {
                return (
                  <div className={item.child ? "permission-row row-child" : "permission-row"} key={`${item.id}_${index}`} style={{ display: item.disable && 'none' }}>
                    <div className="row-left">
                      {
                        item.icon !== '' &&
                        <img src={item.icon} alt="icon-permission" className="icon-permission" />
                      }

                      {t(item.name)}
                    </div>

                    <div className="row-right">
                      {
                        item.actions.map((checkboxItem: any, i: number) => {
                          return (
                            <div className="checkbox-item" key={checkboxItem.label} style={{ visibility: (checkboxItem.disable) && 'hidden'}}>
                              <Checkbox
                                checked={checkboxItem.disable ? false : checkboxItem.selected}
                                crossOrigin="checkbox-permission"
                                className="checkbox"
                                disabled={true}
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              />

                              <div className="checkbox-label">{t(checkboxItem.label)}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const RoleDetail = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <RoleDetailUI
        t={t}
        RoleInformation={RoleInformation}
      />
    )
  } else {
    return (
      <RoleDetailMobileUI
        t={t}
        RoleInformation={RoleInformation}
      />
    )
  }
};

export default RoleDetail;