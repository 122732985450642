import { useEffect, useState } from 'react';
import Desktop from '@layouts/desktop';
import './RoleManagement.scss';
import Breadcrumb from '@components/common/Breadcrumb';
import { Button } from "@material-tailwind/react";
import { PlusIcon } from '@heroicons/react/20/solid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

const RoleManagementUI = (props: any) => {
  const { FilterGroup, Dashboard, t } = props;
  const [search, setSearch] = useState({
    q: '',
    status: '',
  } as any);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(true);
  const [applyDate, setApplyDate] = useState({
    start: '',
    end: ''
  });

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('role-management'),
      url: ''
    }
  ];

  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const [initParams, setInitParams] = useState(true);

  useEffect(() => {
    if (initParams) {
      const keys = Object.keys(paramsObject);

      if (keys?.length > 0) {
        handleInitSearchValue();
      } else {
        setFilter(true);
        setInitParams(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);

  const handleInitSearchValue = () => {
    const searchTemp = _.cloneDeep(search);

    if (paramsObject.q && paramsObject.q !== '') {
      searchTemp.q = paramsObject.q;
    }

    if (paramsObject.status && paramsObject.status !== '') {
      searchTemp.status = paramsObject.status;
    }

    if (paramsObject.gte && paramsObject.gte !== '') {
      setApplyDate({
        start: paramsObject.gte,
        end: paramsObject.lte
      })
    }

    setSearch(searchTemp);

    const intervalInit = setInterval(() => {
      setFilter(true);
      setInitParams(false);
      clearInterval(intervalInit);
    }, 1000);

  }

  useEffect(() => {
    handleUpdateRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, applyDate]);

  const handleUpdateRoute = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('q', search.q);
    newSearchParams.set('status', search.status);
    newSearchParams.set('gte', applyDate.start);
    newSearchParams.set('lte', applyDate.end);

    const newParamsObject = Object.fromEntries([...newSearchParams]);

    const keys = Object.keys(newParamsObject);
    let changeRoute = false;

    if (keys?.length > 0) {
      keys?.forEach((key: string) => {
        if (newParamsObject[key] !== '') {
          changeRoute = true;
        }
      });
    }

    if (changeRoute || !initParams) {
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  }

  return (
    <Desktop>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      <div id="role-management">
        <div className="header-page">
          <span className="header-title">{t('role-management')}</span>

          <a href="/organization/role/create">
            <Button
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
              placeholder="button create account" className="btn-create-account">
              <PlusIcon className="w-6 h-6 mr-2" />

              <span className="btn-title">{t('create-new-role')}</span>
            </Button>
          </a>

        </div>

        <div className="page-body">
          <FilterGroup
            t={t}
            search={search}
            setSearch={setSearch}
            setFilter={setFilter}
            setApplyDate={setApplyDate}
            setPage={setPage}
            applyDate={applyDate}
          />

          <Dashboard
            t={t}
            search={search}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            applyDate={applyDate}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </Desktop>
  )
};

export default RoleManagementUI;