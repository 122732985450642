import { useEffect, useState } from 'react';
import useWindowWidth from "@helpers/InitWidthDevice";
import { Formik, Field, Form } from 'formik';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { Button, Switch, Checkbox } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import ApiService from '@services/ApiService';
import { useNavigate, useParams } from 'react-router-dom';
import EditRoleUI from 'UI/desktop/Organization/RoleManagement/Edit/EditRole';
import EditRoleUIMobile from 'UI/mobile/Organization/RoleManagement/Edit/EditRole';
import { DEFAULT_PERMISSIONS } from '@helpers/constants';

const FormEditRole = (props: any) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const { t } = props;
  const { slug } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);

  const [permission, setPermission] = useState([] as any);

  const [roleInfo, setRoleInfo] = useState(null as any);
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (slug && slug !== '') {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [slug]);

  useEffect(() => {
    if (fetchData) {
      fetchListPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchListPermission = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`role/organization/permissions`);

      if (result && result.length > 0) {
        const arr = [...DEFAULT_PERMISSIONS] as any;

        arr.forEach((item: any) => {
          for (let i = 0; i < result.length; i++) {
            const id = (result[i].resource === '') ? result[i].app : result[i].resource;

            if (id === item.id) {
              if (result[i].action.length === 3) {
                item.disable = false;

                item.actions.forEach((action: any) => {
                  action.selected = false;
                  action.disable = false;
                })
              } else {
                if (result[i].action.length > 0) {
                  item.disable = false;

                  for (let j = 0; j < result[i].action.length; j++) {
                    item.actions.forEach((action: any, index: number) => {
                      if (index === 0) {
                        action.disable = false;
                      }

                      if (result[i].action[j] === action.id) {
                        action.selected = false;
                        action.disable = false;
                      }
                    })
                  }
                }
              }

            }

            if (result[i].app === item.id) {
              item.disable = false;
            }
          }
        });

        fetchRoleInfo(arr);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const fetchRoleInfo = async (pers: any) => {
    setLoading(true);
    try {
      const result = await ApiService.get(`role/${slug}`);

      if (result) {
        setRoleInfo(result);

        if (result.name && result.name.toLowerCase() === 'owner') {
          setActive(false);
        }

        const permission_temp = [...pers];

        if (result.permissions && result.permissions.length > 0) {
          result.permissions.forEach((item: any) => {
            for (let i = 0; i < permission_temp.length; i++) {
              if (item.resource === '') {
                if (item.app === permission_temp[i].id && item.action && item.action.length > 0) {
                  for (let j = 0; j < permission_temp[i].actions.length; j++) {
                    if (item.action.includes(permission_temp[i].actions[j].id)) {
                      permission_temp[i].actions[j].selected = true;
                    }
                  }

                  if (item.action.length === 3) {
                    permission_temp[i].actions[0].selected = true;
                  }
                }
              } else {
                if (item.resource === permission_temp[i].id && item.action && item.action.length > 0) {
                  for (let j = 0; j < permission_temp[i].actions.length; j++) {
                    if (item.action.includes(permission_temp[i].actions[j].id)) {
                      permission_temp[i].actions[j].selected = true;
                    }
                  }

                  if (item.action.length === 3) {
                    permission_temp[i].actions[0].selected = true;
                  }
                }
              }
            }
          });
        }

        permission_temp.forEach((item: any) => {
          if (item.actions.length > 0) {
            let action_count = 0;
            let all = 0;

            item.actions.forEach((action: any) => {
              if (!action.disable) {
                action_count = action_count + 1;
              }

              if (action.selected) {
                all = all + 1;
              };
            });

            if (all === action_count - 1) {
              item.actions[0].selected = true;
            }
            
          }
        });

        setPermission([...permission_temp]);
        setStatus(result.is_active);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setFetchData(false);
    setLoading(false);
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);
    const permissionData: any[] = [];

    permission.forEach((item: any) => {
      if (item.actions.length > 0) {
        const obj = {
          resource: item.child ? item.id : '',
          app: item.child ? item.parent : item.id,
          actions: []
        } as any;

        for (let i = 0; i < item.actions.length; i++) {
          if (item.actions[i].id !== 'all') {
            if (item.actions[i].selected) {
              obj.actions.push(item.actions[i].id);
            }
          }
        }

        permissionData.push(obj);
      }
    })

    const submitData = {
      id: slug,
      name: values.role_name,
      description: values.role_description,
      is_active: status,
      permissions: permissionData
    };

    try {
      const result = await ApiService.put(`role/${slug}`, submitData);

      if (result) {
        setMessage(t('update-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setSubmitting(false);

    setLoading(false);
  }

  const validateText = (value: string) => {
    let error;

    if (!value) {
      error = t("error-field-require");
    } else {
      let str = value;
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      const regex = /[^a-zA-Z0-9 ]+/;

      if (regex.test(str)) {
        error = t("error-special-character");
      }
    }

    return error;
  }

  const handleSelectAction = (value: boolean, checkboxId: string, permissionId: string) => {
    setLoading(true);
    const permission_temp = [...permission];
    permission_temp.forEach((item: any) => {
      if (permissionId === item.id) {
        for (let i = 0; i < item.actions.length; i++) {
          if (checkboxId === 'all') {
            if (!item.actions[i].disable) {
              item.actions[i].selected = value;
            }
          } else {
            if (item.actions[i].id === checkboxId) {
              item.actions[i].selected = value;
            }
          }
        }

        if (!value) {
          item.actions[0].selected = false;
        } else {
          let check_all = true;

          item.actions.forEach((item: any) => {
            if (item.id !== 'all' && !item.selected && !item.disable) {
              check_all = false;
            }
          });

          item.actions[1].selected = true;

          if (check_all) {
            item.actions[0].selected = true;
          }
        }
      }

    });

    permission_temp.forEach((item: any) => {
      if (item.actions.length > 0) {
        let action_count = 0;
        let all = 0;

        item.actions.forEach((action: any) => {
          if (!action.disable) {
            action_count = action_count + 1;
          }

          if (action.selected) {
            all = all + 1;
          };
        });

        if (all === action_count - 1) {
          item.actions[0].selected = true;
        }
        
      }
    });

    setPermission([...permission_temp]);
    setLoading(false);
  }

  if (active) {
    return (
      <div className="form-create-role">
        {
          roleInfo &&
          <Formik
            initialValues={{ role_name: roleInfo.name, role_description: roleInfo.description }}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
          >
            {({
              handleSubmit,
              isSubmitting
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="form-header">{t('edit-role')}</div>
                  <div className="form-body">
                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('status')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Switch
                          checked={status}
                          onChange={() => setStatus(!status)}
                          crossOrigin="switch"
                          ripple={false}
                          className="h-full w-full checked:bg-[#0162D1] switch"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                          onPointerEnterCapture="none" onPointerLeaveCapture="none" />
                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('role-name')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Field name="role_name" validate={validateText}>
                          {({
                            field,
                            meta,
                          }: any) => (
                            <div className="field-group">
                              <input {...field} type="text" className="input" autoComplete="off" placeholder={t('role-name')} maxLength={50} />

                              <div className="text-length">{field.value ? field.value.length : 0}/50</div>

                              {meta.touched && meta.error && (
                                <div className="field-error">{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('role-description')}</span>
                        </div>

                        <Field name="role_description">
                          {({
                            field,
                            meta,
                          }: any) => (
                            <div className="field-group full">
                              <input {...field} type="text" className="input" autoComplete="off" placeholder={t('role-description')} maxLength={100} />

                              <div className="text-length">{field.value ? field.value.length : 0}/100</div>

                              {meta.touched && meta.error && (
                                <div className="field-error">{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('access-permission')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <div className="role-group">
                          {
                            permission.map((item: any, index: number) => {
                              return (
                                <div className={item.child ? "role-row role-row-mobile role-child" : "role-row role-row-mobile"} key={`${item.name}_${index}`} style={{ display: item.disable && 'none' }}>
                                  <div className="role-row-left">
                                    {
                                      item.icon !== '' &&
                                      <img src={item.icon} alt="icon-permission" className="icon-permission" />
                                    }
                                    <div className="role-label">{t(item.name)}</div>
                                  </div>

                                  <div className="role-row-right">
                                    {
                                      item.actions.map((checkboxItem: any) => {
                                        return (
                                          <div className="checkbox-item" key={checkboxItem.label} style={{ visibility: (checkboxItem.disable) && 'hidden'}}>
                                            <Checkbox
                                              onChange={() => handleSelectAction(!checkboxItem.selected, checkboxItem.id, item.id)}
                                              checked={checkboxItem.disable ? false : checkboxItem.selected}
                                              crossOrigin="checkbox-permission"
                                              className="checkbox"
                                              disabled={checkboxItem.disable}
                                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                            />

                                            <div className="checkbox-label">{t(checkboxItem.label)}</div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="btn-group">
                  <Button
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    placeholder="Button Cancel" className="btn-cancel" onClick={() => navigate('/organization/role')}>{t('btn-cancel')}</Button>
                  <Button
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    type="submit" disabled={isSubmitting} placeholder="Button Save" className="btn-save">{t('btn-save')}</Button>
                </div>

                {
                  message !== '' &&
                  <Message
                    message={message}
                    messageType={messageType}
                    setMessage={setMessage}
                  />
                }

                {
                  loading &&
                  <Loader />
                }
              </Form>
            )}
          </Formik>
        }
      </div>
    )
  } else {
    return (
      <div className="access-denied">
        <div className="access-denied__label">{t('access-denied')}</div>
        <div className="access-denied__content">{t('access-denied-content')}</div>
        <div className="access-denied__content">{t('access-denied-content-2')}</div>
      </div>
    )
  }
}

const EditRoleManagement = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <EditRoleUI
        FormEditRole={FormEditRole}
        t={t}
      />
    )
  } else {
    return (
      <EditRoleUIMobile
        FormEditRole={FormEditRole}
        t={t}
      />
    )
  }
};

export default EditRoleManagement;